import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataSharingService {
    private searchDataSubject = new BehaviorSubject<any>(null);
    searchData$ = this.searchDataSubject.asObservable();

    setSearchData(data: any): void {
        this.searchDataSubject.next(data);
    }
}