<h3 mat-dialog-title class="text-bold text-center">Ajouter un utilisateur</h3>

<mat-dialog-content class="custom-dialog-width">

	<form [formGroup]="userForm">
		<div class="flex flex-col flex-auto">
			<!-- Title -->

			<div class="sm:col-span-2 py-2">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
					<mat-label>Nom</mat-label>
					<input formControlName="name" matInput>
				</mat-form-field>
			</div>

			<div class="sm:col-span-2 py-2">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
					<mat-label>Prénoms</mat-label>
					<input formControlName="last_name" matInput>
				</mat-form-field>
			</div>

			<div class="sm:col-span-2 py-2">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
					<mat-label>Téléphone</mat-label>
					<input formControlName="tel" matInput>
				</mat-form-field>
			</div>


			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Rôle</mat-label>
				<mat-select formControlName="role_id">
					<mat-option *ngFor="let role of roles" [value]="role.id">{{ role?.name }}</mat-option>
				</mat-select>
			</mat-form-field>


			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Type de document</mat-label>
				<mat-select formControlName="type_identity">
					<mat-option *ngFor="let item of document_type" [value]="item.key">
						{{ item.label }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<div class="sm:col-span-2 py-2">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
					<mat-label>Numéro de la pièce</mat-label>
					<input formControlName="num_identity" matInput>
				</mat-form-field>
			</div>


			<div *ngIf="!initialUser" class="sm:col-span-2 py-2">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
					<mat-label>Mot de passe généré</mat-label>
					<input formControlName="password" matInput readonly class="password-field">
				</mat-form-field>
			</div>
		</div>
	</form>
<mat-dialog-content>

<mat-dialog-actions class="justify-content-evenly">
	<button mat-raised-button (click)="matDialogRef.close(false)">
		Annuler
	</button>
	
	<button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid || lockDuringSending"
		(click)="send()">
		{{sendTitle}}
	</button>
</mat-dialog-actions>