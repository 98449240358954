import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ISalepoint } from 'src/app/Types/salepoints';
import { HelperFunctions } from 'src/app/helpers/helpers';

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent {
    @Input() salepoints: ISalepoint[] = []

    constructor(
        private router: Router, 
        public helperService:HelperFunctions
    ) { }

    navigateToDetail(salepoint: ISalepoint) {
        this.router.navigate(['/search/detail'], { state: { salepoint } });
    }

    libelleFromStatus(status: string) {
        return this.helperService.getLibelleFromStatus(status)
    }

    getClassFromStatus(status: string) {
        return this.helperService.getStatusClass(status);
    }
}
