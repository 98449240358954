<div class="container">
	<div class="row justify-content-center">
		<div class="col-md-6 col-sm-12 mt-3">
			<div *ngIf="!zone;  else zoneTab">
				<mat-form-field appearance="fill" class="w-100">
					<mat-label>Coordonnées GPS</mat-label>
					<input matInput placeholder="Coordonnées GPS" class="text-black" [(ngModel)]="gps_position"
						[disabled]="searchLock">

					<button mat-icon-button matSuffix matTooltip="position gps" matTooltipPosition="above"
						(click)="localization()">
						<div class="icon">
							<i [class]="iconClass"></i>
						</div>
					</button>
				</mat-form-field>

				<div class="w-100 mt-3">
					<label>Rayon de recherche {{slider.value}} m </label>
					<mat-slider min="0" class="w-100" min="0" max="5000" step="50" (change)="updateSetting()"
						showTickMarks discrete [displayWith]="formatLabel">
						<input matSliderThumb [(ngModel)]="rayon" #slider>
					</mat-slider>
				</div>
			</div>

			<ng-template #zoneTab>
				<mat-form-field class="w-100">
					<mat-label>Zones</mat-label>
					<input type="text"  matInput [(ngModel)]="selectedZone" (ngModelChange)="updateSearchData()" [matAutocomplete]="autoZone"
						(input)="filterZones($event)">
					<mat-autocomplete #autoZone="matAutocomplete">

						<mat-option *ngFor="let item of filteredZones" [value]="item">{{item}}</mat-option>

					</mat-autocomplete>

				</mat-form-field>
			</ng-template>


			<mat-chip-listbox class="mt-3">
				<mat-chip-option *ngFor="let option of statusOptions" [color]="option.color"
					(selectionChange)="updateStatus(option.value)" [value]="option.value">
					{{ option.label }}
				</mat-chip-option>
			</mat-chip-listbox>

			<mat-form-field appearance="fill" class="w-100 mt-3">
				<mat-label>Nom du point de vente</mat-label>
				<input matInput placeholder="Nom du point de vente" class="text-black" [(ngModel)]="selectedSalepoint" (ngModelChange)="updateSearchData()">
			</mat-form-field>
		</div>
	</div>
</div>