import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs';
import { NotificationService } from 'src/app/api/notification.service';
import { IForfait } from 'src/app/Types/forfait';
import { ForfaitService } from 'src/app/api/forfait.service';

@Component({
    selector: 'app-add-forfait-dialog',
    templateUrl: './add-forfait-dialog.component.html',
    styleUrls: ['./add-forfait-dialog.component.scss']
})
export class AddForfaitDialogComponent implements OnInit {
    forfaitForm: FormGroup = this._formBuilder.group({
        title: ['', [Validators.required]],
        amount: ['', [Validators.required]],
        duree_label: ['', [Validators.required]],
        duree: [0, [Validators.required]],
        description: [''],
    });
    roles: any;
    document_type: any[] = [];
    lockDuringSending = false;
    initialForfait: IForfait;
    sendTitle: string = '';
    constructor(
        public matDialogRef: MatDialogRef<AddForfaitDialogComponent>,
        private _formBuilder: FormBuilder,
        private forfaitService: ForfaitService,
        private notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) public forfait: IForfait,
    ) {
        this.initialForfait = forfait;
    }

    ngOnInit(): void {

        if (this.initialForfait) {
            this.initForfaitForm(this.initialForfait);
            this.sendTitle = 'Modiffier';


        } else {
            this.sendTitle = 'Enregister';
            

        }
    }

    /**
     * Formulaire de modification
     */
    initForfaitForm(forfait: IForfait): void {
        this.forfaitForm.get('title')?.setValue(forfait.title);
        this.forfaitForm.get('duree')?.setValue(forfait.duree);
        this.forfaitForm.get('duree_label')?.setValue(forfait.duree_label);
        this.forfaitForm.get('amount')?.setValue(forfait.amount);
        this.forfaitForm.get('description')?.setValue(forfait?.description);

    }



    post(formData: any) {
        this.forfaitService.createForfait(formData).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.matDialogRef.close(true);
            }),
            catchError((fail) => {
                
                this.notificationService.showError(fail.error.message);
                this.lockDuringSending = false;
                throw fail;
            })
        ).subscribe()
    }

    update(formData: any) {
        this.forfaitService.updateForfait(this.initialForfait.id as number, formData).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.matDialogRef.close(true);
            }),
            catchError((fail) => {
                this.notificationService.showError(fail.error.message);
                this.lockDuringSending = false;
                throw fail;
            })
        ).subscribe()
    }

    send() {
        this.lockDuringSending = true;

        const formData = { ...this.forfaitForm.value };

        console.log(formData);

        if (this.initialForfait) {
            return this.update(formData);
        }
        return this.post(formData); 

    }
}
