import { Injectable } from '@angular/core';
import { CanActivate, NavigationEnd, Router } from '@angular/router';
import { AppStorageService } from '../helpers/app-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    activeRoute: string = '';

    constructor(
        private router: Router,
        private appStorageService: AppStorageService,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.activeRoute = event.url;
            }
        });
    }

    canActivate(): boolean {

        if (!this.appStorageService.getToken()) {
            this.router.navigate(['connexion']);
            return false;
        }
        return true;
    }
}