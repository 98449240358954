import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppStorageService } from '../helpers/app-storage.service';
import { environment } from 'src/environments/environment';

export class BaseRepository {
    url = `${environment.apiUrl}`;
    loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loading$: Observable<boolean> = this.loadingSubject.asObservable();

    constructor(
    protected controllerName: string,
    public http: HttpClient,
    public appStorageService: AppStorageService,
    ) {}

    public getAll(httpOptions?: any): Observable<any> {
        return this.http.get<any>(
            this.url + this.controllerName,
            this.appStorageService.getHttpOptions(httpOptions),
        );
    }

    public getForId(id: number, httpOptions?: any): Observable<any> {
        return this.http.get<any>(
            this.url + this.controllerName + '/' + id,
            this.appStorageService.getHttpOptions(httpOptions),
        );
    }

    public update(id: number, body: any, httpOptions?: any): Observable<any> {
        return this.http.put<any>(
            this.url + this.controllerName + '/' + id,
            body,
            this.appStorageService.getHttpOptions(httpOptions),
        );
    }

    public add(body: any, httpOptions?: any): Observable<any> {
        return this.http.post<any>(
            this.url + this.controllerName,
            body,
            this.appStorageService.getHttpOptions(httpOptions),
        );
    }

    public delete(id: number, httpOptions?: any): Observable<any> {
        return this.http.delete<any>(
            this.url + this.controllerName + '/' + id,
            this.appStorageService.getHttpOptions(httpOptions),
        );
    }
    public getManagers(id: number, httpOptions?: any): Observable<any> {
        return this.http.get<any>(
            this.url + this.controllerName + '/' + id + '/managers',
            this.appStorageService.getHttpOptions(httpOptions),
        );
    }
}
