<h3> {{title}} </h3>

<div class="row py-3 mb-3">

    <button mat-fab extended color="primary" (click)="addUser()">
        <mat-icon>add_circle</mat-icon>
        Ajouter un utilisateur
    </button>

    <button mat-fab extended color="warn" (click)="ngOnInit()" class="m-1">
        <mat-icon>people</mat-icon>
        Tout
    </button>

    <button mat-fab extended style="background-color: lightslategray;" (click)="filter('super-admin')" class="m-1">
        <mat-icon>people</mat-icon>
        Super Admins
    </button>

    <button mat-fab extended style="background-color: tan;" (click)="filter('corporate')" class="m-1">
        <mat-icon>bubble_chart</mat-icon>
        Corporates
    </button>

    <button mat-fab extended style="background-color: cadetblue;" (click)="filter('master')" class="m-1">
        <mat-icon>stars</mat-icon>
        Masters
    </button>

    <button mat-fab extended style="background-color: #994ae3;" (click)="filter('agent')" class="m-1">
        <mat-icon>face</mat-icon>
        Agents
    </button>

    <button mat-fab extended style="background-color: chocolat;" (click)="filter('operateur')" class="m-1">
        <mat-icon>people</mat-icon>
        Operateurs
    </button>

    <button mat-fab extended style="background-color: brown;" (click)="filter('lead')" class="m-1">
        <mat-icon>pets</mat-icon>
        Leads
    </button>

    <button mat-fab extended color="accent" class="text-white m-1" (click)="filter('admin')">
        <mat-icon>security</mat-icon>
        Admins
    </button>

    <button mat-fab extended class="text-white m-1 bg-green-500" (click)="getBasics()">
        <mat-icon>nature_people</mat-icon>
        Basics
    </button>

    <button mat-fab extended class="bg-dark text-white m-1" (click)="getTrashedUsers()">
        <mat-icon>delete</mat-icon>
        Corbeille
    </button>

</div>


<p-table *ngIf="users.length!=0" #dt styleClass="p-datatable-sm " [value]="dataSource" responsiveLayout="scroll" [rows]="10"
    [globalFilterFields]="['No', 'nom']" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 20, 30]"
    [showCurrentPageReport]="true" currentPageReportTemplate="De {first} à {last} sur {totalRecords}"
    selectionMode="single" [rowHover]="true" dataKey="id">
    <!-- <ng-template pTemplate="caption">
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="onGlobalFilter(dt, $event)"
                                    placeholder="Rechercher..." class="w-full sm:w-auto" />
                            </span>
                        </div>
                    </ng-template> -->
    <ng-template pTemplate="header">
        <tr>
            <th class="head" pSortableColumn="No">
                No.
                <p-sortIcon field="No"></p-sortIcon>
            </th>

            <th class="head" pSortableColumn="nom">
                Nom et prénoms
                <p-sortIcon field="nom"></p-sortIcon>
            </th>
            <th class="head" pSortableColumn="tel">
                Téléphone
                <p-sortIcon field="tel"></p-sortIcon>
            </th>
            <th class="head" pSortableColumn="group">
                Groupe
                <p-sortIcon field="group"></p-sortIcon>
            </th>
            <th class="head" pSortableColumn="Validation">
                Action
                <p-sortIcon field="Validation"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element>
        <tr class="line" (click)="edit(element)">
            <td style="width: 20%; min-width: 8rem">
                <span class="p-column-title">No</span>
                {{ element.id }}
            </td>

            <td style="width: 20%; min-width: 8rem">
                <span class="p-column-title">Nom et prénoms</span>
                {{element?.name}} {{element?.last_name}}
            </td>
            <td style="width: 20%; min-width: 8rem">
                <span class="p-column-title">tel</span>
                {{element?.tel}}
            </td>
            <td style="width: 20%; min-width: 8rem">
                <span class="p-column-title">group</span>
                {{getRoleLabel(element?.role_id)}}
            </td>

            <td (click)="$event.stopPropagation()" style="width: 20%; min-width: 8rem">


                <mat-icon [matMenuTriggerFor]="menu" aria-hidden="false">more_vert</mat-icon>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="delete(element)">Supprimer</button>
                </mat-menu>

                <!-- <p-checkbox (click)="onActivateMatiere(element)" [(ngModel)]="element.Activated" [binary]="true" inputId="binary"></p-checkbox> -->
            </td>

        </tr>
    </ng-template>
</p-table>

<div class="spinner-container" *ngIf="users.length===0">

    <mat-progress-spinner *ngIf="users.length===0" [diameter]="50"
        mode="indeterminate"></mat-progress-spinner>

</div>