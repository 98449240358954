<div class="row text-center">
    <div class="col-md-12 mt-3 mb-2 ">
        <div class="file-upload">
            <input class="w-95 " id="file-input1" type="file" (change)="onRegisterFile($event,'file-name-placeholder1','place1')">
            <label id="place1" for="file-input1">Image (1200 x 1200 px )</label>
            <span id="file-name-placeholder1"></span>

            <button type="button" class="folder-icon" (click)="openFileExplorer('file-input1')">
                <i class="bi bi-file-earmark-arrow-down-fill"></i>
            </button>

        </div>

        <input class="text mb-3 w-95 mx-auto p-3" type="text" [(ngModel)]="titre" placeholder="Titre">

        <textarea  class="text mx-auto mb-3 w-95 p-3" placeholder="Contenu (300 mots)"  [(ngModel)]="content" name="" id="" cols="30" rows="20">

        </textarea>

        <input class="text mb-3 w-95 mx-auto p-3" type="text" [(ngModel)]="userFilter" placeholder="Filtre utilisateurs">
        <div class="d-flex justify-content-center">

            
            <div class="input-wrapper mt-3">
                <label for="datedebut">Date de début</label>
                <input id="datedebut" type="date" class="champ">
            </div>
            <div class="input-wrapper mt-3">
                <label for="datefin">Date de fin</label>
                <input id="datefin" type="date" class="champ">
            </div>
            <div class="input-wrapper mt-3">
                <label for="heuredeb">Heure de début</label>
                <input id="heuredeb" type="time" class="champ">
            </div>

            <div class="input-wrapper mt-3">
                <label for="heurefin">Heure de fin</label>
                <input id="heurefin" type="time" class="champ" >
            </div>
        </div>

        <div class="mt-5 d-flex justify-content-center">
            
            <button class="btn" (click)="CreateAffiche()" type="submit">Envoyer</button>
            
        </div>
    </div>
   
</div>