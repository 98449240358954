import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AppStorageService } from './app-storage.service';
import { NotificationService } from '../notification.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    public isMaintenanceModeOn: boolean = false;

    constructor(private router: Router, private appStorageService: AppStorageService,
    private notificationService: NotificationService) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<any> {

        // If Maintainance Mode On
        if (this.isMaintenanceModeOn) {
            this.router.navigate(['/maintenance']);
        }

        const token = this.appStorageService.getToken();

        if (token) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }

        return next.handle(req).pipe(
            catchError((resp: HttpErrorResponse) => {
                if (resp.status === 403) {
                    this.appStorageService.removeToken();
                    this.appStorageService.removeRefreshToken();
                    this.router.navigate(['/connexion']);
                    this.notificationService.showError(resp.error.message);
                }
                return throwError(() => resp.error.message);
            })
        );

    }
}
