<div>
    <div class="logo">
        <img src="../../assets/img/logo.jpeg" width="100px" height="100px" id="icon" alt="User Icon" />
        <h1>uve<span style="color: #06D9FD;">on</span></h1>
        <p>ADMINISTRATEUR / MONITORINg</p>
    </div>
    <div class="logLink">
        <div class="connexion" routerLink="/connexion">Se connecter</div>
        <!-- <div class="inscription" routerLink="/inscription">S'inscrire</div> -->
    </div>
</div>
