import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStorageService } from './helpers/app-storage.service';
import { BaseRepository } from './base/base-repository';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionService extends BaseRepository {
    constructor(http: HttpClient, appStorageService: AppStorageService) {
        super('subscriptions', http, appStorageService);
    }

    getSubscriptions(salepoint_id: number) {
        return this.http.get<any>(this.url+ 'salepoints/' + salepoint_id + "/" + this.controllerName, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    updateSubscriptions(salepoint_id: number, val: any) {
       
        return this.http.put<any>(this.url + 'salepoints/' + salepoint_id + "/" + this.controllerName+ "/" + val.id, {validated:val.validated}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }
}
