import { Component, OnInit } from '@angular/core';
import { SalepointService } from '../../../../api/salepoint.service';
import { SettingsService } from 'src/app/api/setting.service';
import { NotificationService } from 'src/app/api/notification.service';
import { catchError, tap } from 'rxjs';


@Component({
    selector: 'app-forth-step',
    templateUrl: './agency-setting.component.html',
    styleUrls: ['./agency-setting.component.scss']
})
export class AgencySettingComponent implements OnInit {

    pdvId?: number
    agenceType?:string
    listAgenceType: string[] = []

    constructor(
        private pdvService: SalepointService,
        private notificationService: NotificationService,
        private settingsService: SettingsService,
    ) {
        this.pdvService.commonMessage.subscribe(m=>{
            this.pdvId=m.pdvId
        })
        this.settingsService.settings$.subscribe((data) => {
            if (data) {
                this.listAgenceType = data.configuration_agence
            }
        });
    }

    ngOnInit(): void {
        this.pdvService.getSalepointById(this.pdvId!).subscribe( pdv=>{
            this.agenceType=pdv.data.type
        })
    }

    next() {
        this.pdvService.sendData({step:5, pdvId:this.pdvId})
    }
    prev() {
        this.pdvService.sendData({step:2, pdvId:this.pdvId})
    }


    register(agenceType:string) {
        
        const data={
            type: agenceType,
        }

        this.pdvService.updatePDVFinal(data, this.pdvId as number).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.next()
            }),
            catchError((errorMessage) => {
                this.notificationService.showError(errorMessage);
                throw errorMessage;
            })
        ).subscribe();
    }
}
