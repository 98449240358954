<div class="row">
    <div class="col-md-12 mt-3 mb-2 bg-danger">
        <div class="red py-2">
            <h4 class="text-center text-white">
                Sans les documents de l’agence,<br>
                votre niveau de demande sera bloqué à 500 000 FCFA

            </h4>
        </div>
    </div>
    <div class="col-md-6 mt-4">

        <div class="title">
            <h5><i class="bi bi-camera-fill"></i> Ajouter les documents de l’agence</h5>
        </div>
        <div class="file-upload">
            <input id="file-input1" type="file" (change)="onRegisterFile($event,'file-name-placeholder1','place1')">
            <label id="place1" for="file-input1">Registre de commerce</label>
            <span id="file-name-placeholder1"></span>

            <button type="button" class="folder-icon" (click)="openFileExplorer('file-input1')">
                <i class="bi bi-file-earmark-arrow-down-fill"></i>
            </button>

        </div>
        <div class="file-upload">
            <input id="file-input2" type="file" (change)="onTaxeFile($event,'file-name-placeholder2','place2' )">
            <label id="place2" for="file-input2">Déclaration Fiscale d’existence</label>
            <span id="file-name-placeholder2"></span>

            <button type="button" class="folder-icon" (click)="openFileExplorer('file-input2')">
                <i class="bi bi-file-earmark-arrow-down-fill"></i>
            </button>

        </div>
        <div class="file-upload">
            <input id="file-input3" type="file" (change)="onLeaseFile($event,'file-name-placeholder3','place3' )">
            <label id="place3" for="file-input3">Contrat de bail du local ou facture CIE ou SODECI</label>
            <span id="file-name-placeholder3"></span>

            <button type="button" class="folder-icon" (click)="openFileExplorer('file-input3')">
                <i class="bi bi-file-earmark-arrow-down-fill"></i>
            </button>

        </div>

    </div>
    <div class="col-md-6 mt-4">
        <div class="title">
            <h5><i class="bi bi-camera-fill"></i> Ajouter les documents du gerant</h5>
        </div>
        <div class="file-upload">
            <input id="file-input4" type="file" (change)="oncniFrontFile($event,'file-name-placeholder4','place4')">
            <label id="place4" for="file-input4">photo recto de la pièce d’identité</label>
            <span id="file-name-placeholder4"></span>

            <button type="button" class="camera-icon" (click)="openFileExplorer('file-input4')">
                <i class="bi bi-camera-fill"></i>
            </button>

        </div>
        <div class="file-upload">
            <input id="file-input5" type="file" (change)="oncniBacktFile($event,'file-name-placeholder5','place5')">
            <label id="place5" for="file-input5">photo verso de la pièce d’identité</label>
            <span id="file-name-placeholder5"></span>

            <button type="button" class="camera-icon" (click)="openFileExplorer('file-input5')">
                <i class="bi bi-camera-fill"></i>
            </button>

        </div>

        <div class="mt-5 d-flex">
            <button class="btn" (click)="prev()"><i class="bi bi-caret-left-fill"></i>Retour</button>
            <button class="btn" (click)="CreateSalePoint()" type="submit">Créer mon agence</button>

        </div>
    </div>

</div>