<h3 mat-dialog-title class="text-bold text-center">Choisir un gérant</h3>

<mat-dialog-content class="custom-dialog-width">

	<div *ngFor="let manager of managerList" class="d-flex justify-content-between my-3 line"
	[mat-dialog-close]="true"
	(click)="choose(manager.user_id)"
	 style="background-color: #f4f4f4; 
					padding: 10px; cursor: pointer;">
		<div><mat-icon class="fs-4">account_circle</mat-icon></div>
		<div>{{manager.user}} + {{manager.type}} </div>
		<div>
			<mat-icon>folder</mat-icon>
			<mat-icon>phone</mat-icon>
		</div>
	</div>
<mat-dialog-content>

