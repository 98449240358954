import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';
import { AppStorageService } from './helpers/app-storage.service';
import { BaseRepository } from './base/base-repository';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class SettingsService extends BaseRepository {
    
    private settingsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    settings$ = this.settingsSubject.asObservable();

    constructor(
        http: HttpClient,
        appStorageService: AppStorageService,
        private notificationService: NotificationService
    ) {
        super('settings', http, appStorageService);
        this.getSettings().subscribe();
    }

    public getSettings(httpOptions?: any): Observable<any> {
        this.loadingSubject.next(true); // Affiche le loader
        return this.http.get<any>(this.url + 'settings', this.appStorageService.getHttpOptions(httpOptions)).pipe(
            tap((data) => {
                this.settingsSubject.next(data);
                this.loadingSubject.next(true); // Masque le loader
            }),
            catchError((error) => {
                this.notificationService.showError(error);
                this.loadingSubject.next(true); // Masque le loader en cas d'erreur
                throw error;
            })
        );
    }

}
