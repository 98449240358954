<div class="row justify-content-center">
	<div class="col-md-3 option" routerLink="banniere">
		<div class="icon">
			<i class=" bi bi-gear"></i>
		</div>
		<h4>Créer <br>
			Une banière publicitaire</h4>
	</div>
	<div class="col-md-3 option" routerLink="affiche">
		<div class="icon">
			<i class=" bi bi-collection-play-fill"></i>
		</div>
		<h4>Créer<br>
			Une affiche publicitaire</h4>
	</div>
</div>