<nav class="navbar top mb-5">
    <div class="container">
        <div class="d-flex align-items-center">

            <mat-icon class="text-white ficon" [matMenuTriggerFor]="menu" aria-hidden="false"
                aria-label="Example home icon">account_circle</mat-icon>

            <mat-menu #menu="matMenu">
                <button (click)="logout()" style="color: #19265D; font-weight: bold;" mat-menu-item>Se
                    déconnecter</button>
                <button style="color: #19265D; font-weight: bold;" mat-menu-item>Profile</button>
                <button style="color: #19265D; font-weight: bold;" mat-menu-item>Settings</button>
            </mat-menu>
            <h4 class="text-white">{{session.user.name}}</h4>

        </div>

        <div class="d-flex gap-5 logo">
            <h3>uve<span style="color: #fff;">on</span></h3>
        </div>
    </div>
</nav>
<div class="container grdBlocck">

    <div class="container text-white">
        <div class="row justify-content-evenly">
            <ng-container *ngFor="let block of menuList">
                <div class="block mt-3 d-flex justify-content-start" [routerLink]="block.route">
                    <div class="me-4"><i class="{{block.icon}} icon"></i></div>
                    <h3>{{block.title}}</h3>
                </div>
            </ng-container>
        </div>
        <div class="spinner-container" *ngIf="menuList.length===0">

            <mat-progress-spinner *ngIf="menuList.length===0" [diameter]="50"
                mode="indeterminate"></mat-progress-spinner>

        </div>
    </div>
</div>