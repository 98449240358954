import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AuthorizationRoutingModule } from './authorization-routing.module';
import { AuthorizationComponent } from './authorization.component';
import { LayoutModule } from '../layout/layout.module';
import { BrowserModule } from '@angular/platform-browser';
import { OptionsComponent } from './options/options.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { UsersComponent } from './users/users.component';
import { MatMenuModule } from '@angular/material/menu';
import { PdvFormModule } from '../dashboard/pdv-form/pdv-form.module';
import { AbonnementComponent } from './abonnement/abonnement.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ForfaitComponent } from './forfaits/forfait.component';
import { NumberListComponent } from './number-list/number-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NumberServiceClientComponent } from './number-service-client/number-service.component';


@NgModule({
    declarations: [
        AuthorizationComponent,
        OptionsComponent,
        UsersComponent,
        AbonnementComponent,
        ForfaitComponent,
        NumberListComponent,
        NumberServiceClientComponent
    ],
    imports: [
        MatMenuModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatPaginatorModule,
        MatTableModule,
        BrowserModule,
        CommonModule,
        PdvFormModule,
        LayoutModule,
        AuthorizationRoutingModule,
        TableModule,
        ButtonModule,
        PaginatorModule,
        CheckboxModule,
        InputTextModule,
        DropdownModule,
        DatePipe,
        InputSwitchModule,
        MatProgressSpinnerModule,    
    ]
})
export class AuthorizationModule { }
