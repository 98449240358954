import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



@Component({
    selector: 'app-manager-detail-dialog',
    templateUrl: './manager-detail-dialog.component.html',
    styleUrls: ['./manager-detail-dialog.component.scss']
})
export class ManagerDetailDialogComponent {
    manager: any;
    salepoint_id:any
    constructor(
        public matDialogRef: MatDialogRef<ManagerDetailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.manager = data.manager;
        this.salepoint_id = data.id
       
    }

    
    
    
    
}
