import { Injectable } from '@angular/core';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {
    private socket$: WebSocketSubject<any>;

    constructor() {
        // URL du serveur WebSocket
        const url = 'ws://localhost:6001';

        // Options de connexion (facultatif)

        // Créer une instance WebSocket avec l'URL et les options
        this.socket$ = webSocket(url);
    }
	
	

    sendMessage(message: any) {
        this.socket$.next(message);
    }

    // Écoutez les messages du serveur
    getMessage() {
        return this.socket$.asObservable();
    }
}
