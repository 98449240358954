<h3 mat-dialog-title class="text-bold text-center">Ajouter un nouveau compte</h3>

<mat-dialog-content class="custom-dialog-width">

    <form class="image-selection-form" [formGroup]="docForm">
       
        <mat-form-field class="example-full-width mb-3 zonefield mt-2" appearance="outline">
            <mat-label>TYPE</mat-label>
            <mat-select formControlName="fileType">
                <mat-option *ngFor="let doc of docType" [value]="doc.value">
                    {{doc.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="mt-2">
            <mat-label>Date d'expiration du document</mat-label>
            <input matInput [matDatepicker]="endDate" formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>

        <div class="file-upload">
            <input id="file-input1" type="file" (change)="onRegisterFile($event,'file-name-placeholder1','place1')">
            <label id="place1" for="file-input1">Upload</label>
            <span id="file-name-placeholder1"></span>

            <button type="button" class="folder-icon" (click)="openFileExplorer('file-input1')">
                <i class="bi bi-file-earmark-arrow-down-fill"></i>
            </button>

        </div>

        

        <div class="row mt-5">
            <button class="btn bg-danger" (click)="matDialogRef.close(false)">
                Annuler
            </button>

            <button [mat-dialog-close]="true" class="btn bg-success" type="submit" (click)="updateDocSalePoint()">
                Créer
            </button>
        </div>
    </form>
    <mat-dialog-content>