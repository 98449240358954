import { Component } from '@angular/core';

@Component({
    selector: 'app-communication',
    templateUrl: './communication.component.html',
    styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent {

}
