import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from 'src/app/api/setting.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { IOperateur } from 'src/app/Types/operateurs';
import { SalepointService } from 'src/app/api/salepoint.service';
import { LoaderService } from 'src/app/api/base/loader.service';
import { AccountNumber } from 'src/app/Types/pdv-model';
import { NotificationService } from 'src/app/api/notification.service';

@Component({
    selector: 'app-add-account-dialog',
    templateUrl: './add-account-dialog.component.html',
    styleUrls: ['./add-account-dialog.component.scss']
})
export class AddAccountDialogComponent {

    
    operateurs: IOperateur[] = [];
    operateur?:string
    accountsList: any[] = []
    CopyaccountsList: any[] = []
    filteredMasters: any[] = []
    loadedmaster: any[] = []
    master: boolean = false;
    masterID?: string
    chercher?: string
    pdvId?: number
    createMasterForm:UntypedFormGroup;
    selectedImage?: IOperateur | null;

    constructor(private pdvService: SalepointService,
        private builder: UntypedFormBuilder,
        private notificationService: NotificationService,
        private loaderService: LoaderService,
        private settingsService: SettingsService,
        private fb:UntypedFormBuilder,
        public matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,


    ) {
        this.pdvId=data.id
        this.getSettings();
        this.loadAccounts()
        this.createMasterForm= this.createAccountForm()
    
    }

  

    getSettings() {
        this.loaderService.showLoader(); // Affiche le loader
        this.settingsService.settings$.subscribe((data) => {
            if (data) {
                this.operateurs = data.operateurs
            }
            this.loaderService.hideLoader(); // Masque le loader
        });
    }

    loadAccounts() {
        this.pdvService.getAccountNumberList(this.pdvId!).subscribe((pdv) => {
            this.accountsList = pdv.data
            for (const acc of this.accountsList) {
                this.showMasterName(acc)
            }
        })
    }

    selectImage(image: IOperateur) {
        if (this.selectedImage === image) {
            this.selectedImage = null;
        } else {
            this.selectedImage = image;
        }
        const img = document.querySelectorAll('.image');
        img.forEach(img => {
            img.classList.remove('selected');
        });
        const index = this.operateurs.indexOf(image)
        const selectedImg = img[index];
        selectedImg.classList.add('selected');

        this.masterList(image.name)
    }
    loadMasterList(){
        const formdata = this.createMasterForm.getRawValue()
        this.masterList(formdata.operateur!)
    }

    masterList(operateur: string) {
        this.pdvService.getMasterList(operateur).subscribe((master) => {
            this.loadedmaster = master.data
            this.filteredMasters = master.data
            this.notificationService.showSuccess(this.filteredMasters.length + " master(s) " + operateur + " trouvés");
        })
    }

    showLogo(name: string): string {
        const operateur = this.operateurs.find(op => op.name.toLowerCase() === name.toLowerCase())
        if (operateur) {
            return operateur.logo
        } else {
            return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwp3I-2Bte7Qs6PDDXYSonv_t8vSI9lzh3Wg&usqp=CAU"
        }
    }


    onKey() {
        const value = this.createMasterForm.getRawValue()

        this.filteredMasters = this.search(value.Search)

    }

    search(value: string) {
        const filter = value.toLowerCase()
        if (filter === "" || filter === " ") {
            return this.loadedmaster
        } else {
            return this.loadedmaster.filter(option => option.name.toLowerCase().includes(filter))
        }
    }

    deletePDV(account: any) {

        this.pdvService.deleteAccountNumber(this.pdvId!, account.id).subscribe(
            (response) => {
                this.notificationService.showSuccess(response.message);
                const index = this.CopyaccountsList.indexOf(account)
                this.CopyaccountsList.splice(index, 1)
            },
            (errorMessage) => {
                this.notificationService.showError(errorMessage);
            }
        )


    }
    createAccountForm():UntypedFormGroup{
        return this.fb.group({
            masterId: ['', Validators.required],
            tel: ['', Validators.required],
            master: [this.master, Validators.required],
            Search: [this.chercher],
            operateur:[this.operateur],
        })
    }
  

    // filterMaster(val: Event) {
    //     console.log(val)
    //     const value: string = (val.target as HTMLInputElement).value
    //     if (!value) {
    //       this.filteredMasters = this.loadedmaster.slice(); 
    //     } else {
    //       const filterValue = value.toLowerCase(); 
    //       this.filteredMasters = this.loadedmaster.filter(op => op.name.toLowerCase().includes(filterValue));
    //     }
    // }

    showMasterName(acc: any) {
        if (acc.master_id) {
            this.pdvService.getMasterById(acc.master_id).subscribe((master) => {
                const masterName = {
                    id: acc.id,
                    operateur: acc.operateur,
                    master_name: master.data.name,
                    tel: acc.tel
                }
                if (!this.CopyaccountsList.find(account => account.id === masterName.id)) {
                    this.CopyaccountsList.push(masterName)
                }


            })
        } else {
            const masterName = {
                id: acc.id,
                operateur: acc.operateur,
                master_name: "Master",
                tel: acc.tel
            }
            if (!this.CopyaccountsList.find(account => account.id === masterName.id)) {
                this.CopyaccountsList.push(masterName)
            }



        }



    }

    registerMaster() {

        const formdata = this.createMasterForm.getRawValue()
        // const salepoint_id = localStorage.getItem('pdvId')

        let data: AccountNumber = {
            salepoint_id: String(this.pdvId) ? String(this.pdvId) : "",
            operateur: formdata.operateur,
            tel: formdata.tel,
            master_id: formdata.masterId,
            type: ""
        }

        if (this.master === true) {
            data = {
                salepoint_id: String(this.pdvId) ? String(this.pdvId) : "",
                operateur: formdata.operateur,
                tel: formdata.tel,
                type: "master"
            }
        }
        
        this.pdvService.createAccountNumber(data).subscribe(
            (response) => {
                this.notificationService.showSuccess(response.message);

                this.loadAccounts()

                this.createMasterForm.reset()
            },
            (errorMessage) => {
                this.notificationService.showError(errorMessage);
                this.createMasterForm.reset()
            })
        // this.addMaster(data)
            
    }
}
