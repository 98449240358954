<h3 mat-dialog-title class="text-bold text-center">Modifier les informations de l'agent</h3>

<mat-dialog-content class="custom-dialog-width">

	<div class=" d-flex justify-content-center align-items-center"
					style="width: 100%; margin-bottom: 35px;">

					<div style="margin-right: 25px;">
						<div>
							<p class="idManager">{{manager ? manager.id : "Id du gérant"}} </p>
						</div>

						<div>
							<img id="place"
								src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
								width="150" height="150" alt="photo" class="mt-2">
						</div>

					</div>

					<div style="margin-left: 25px;">
						<div>
							<p class="managerInfo">{{manager ? manager.last_name : "Nom"}}</p>
						</div>
						<div>
							<p class="managerInfo">{{manager ? manager.name : "Prénoms"}} </p>
						</div>
						<div>
							<p class="managerInfo">{{manager ? manager.tel : "Numero de téléphone"}}</p>
						</div>
						<p class="btnmanager bg-danger" [mat-dialog-close]="true" style="cursor: pointer;">
							Retirer
						</p>

					</div>
				</div>
<mat-dialog-content>

