import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStorageService } from './helpers/app-storage.service';
import { BaseRepository } from './base/base-repository';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseRepository {
    constructor(http: HttpClient, appStorageService: AppStorageService) {
        super('users', http, appStorageService);
    }

    public getAllDeleted(httpOptions?: any): Observable<any> {
        return this.http.get<any>(
            this.url + 'deleted-users',
            this.appStorageService.getHttpOptions(httpOptions),
        );
    }

    public search(search: string, httpOptions?: any): Observable<any> {
        return this.http.get<any>(
            this.url + this.controllerName + search,
            this.appStorageService.getHttpOptions(httpOptions),
        );
    }
}
