<div class="row">
    <div class="col-md-6 mt-4">
        <div class="title">
            <h5><i class="bi bi-wallet2"></i> Ajouter un compte</h5>
        </div>
        <p>Clique sur le produit dont tu es le master (distributeur)</p>
        <form class="image-selection-form" [formGroup]="createMasterForm">
            <div class="image-list">
                <div *ngFor="let image of operateurs" class="image-container" (click)="selectImage(image)">
                    <img [src]="image.logo" alt="Image.nom" class="image"
                        [ngClass]="{ 'selected': image === selectedImage}">
                </div>
            </div>
            <div class="mt-3">
            <mat-checkbox color="primary" [(ngModel)]="master" formControlName="master" class="example-margin">Je suis master</mat-checkbox>
            </div>
            <mat-form-field *ngIf="!master" class="example-full-width mb-3 zonefield" appearance="outline">
                <mat-label>Master</mat-label>
                <mat-select formControlName="masterId" [(ngModel)]="masterID" >
                    <input formControlName="Search" (keyup)="onKey()" class="p-3 textSearch w-100"
                placeholder="Recherher ici...">
                    <mat-option  *ngFor="let master of filteredMasters" [value]="master.id">
                      {{master.name}}
                    </mat-option>
                </mat-select>
              </mat-form-field>

            <label class="mt-3" for="">Renseigne dans <span style="color:#19265D;"><b>“numéro de
                        compte”</b></span><br>
                <b>ton compte principal / ta tête de pond</b>
            </label>
            <input formControlName="tel" class="text" type="text" placeholder="Numéro de compte" [readonly]="!selectedImage">

            <div class="mt-5 d-flex justify-content-center">
                <button class="btn w-75" (click)="registerMaster()" [disabled]="(!master && !masterID)">Valider</button>
            </div>
        </form>
    </div>
    <div class="col-md-6" >
        <div class="fond" style= "height: 60vh; overflowY: scroll; overflowX: hidden">
            <table class="table tab"  *ngIf="accountsList.length!==0">
                <tr *ngFor="let account of CopyaccountsList; ">
                    <td><img width="50px" height="50px" style="border-radius: 50px;" [src]="showLogo(account.operateur)" alt="Image.nom" class="image"> </td>
                    <td>{{account.master_name}} </td>
                    <td>{{account.tel}}</td>
                    <td><i (click)="deletePDV(account)" class="bi bi-trash-fill del"></i></td>
                </tr>
                
            </table>
        </div>
        <div class="mt-5 d-flex justify-content-center">
            <button class="btn w-75" (click)="next()">Suivant <i class="bi bi-caret-right-fill"></i></button>
        </div>
    </div>

</div>