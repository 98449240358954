import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-success-page',
    templateUrl: './success-page.component.html',
    styleUrls: ['./success-page.component.scss']
})
export class SuccessPageComponent {

    constructor(
        private route:Router
    ){}

    backhome(){
        this.route.navigateByUrl('/accueil')
    }
}
