import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { catchError, tap } from 'rxjs';
import { ISalepointSearch } from 'src/app/Types/salepointSearch';
import { DataSharingService } from 'src/app/api/base/data.sharing.service';
import { LoaderService } from 'src/app/api/base/loader.service';
import { SalepointService } from 'src/app/api/salepoint.service';
import { NotificationService } from 'src/app/api/notification.service';

@Component({
    selector: 'app-search-pdv',
    templateUrl: './search-pdv.component.html',
    styleUrls: ['./search-pdv.component.scss']
})
export class SearchPdvComponent implements OnInit {
    isLoading: boolean = true;
    searchData?: ISalepointSearch
    foundSalepoints: any;
    params = new URLSearchParams();

    length = 0;
    pageSize = 0;
    pageIndex = 0;

    showFirstLastButtons = true;
    disabled = false;

    pageEvent: PageEvent | undefined;

    constructor(
        private loaderService: LoaderService,
        private dataSharingService: DataSharingService,
        private salepointService: SalepointService,
        private notificationService: NotificationService,
    ) { }
    ngOnInit(): void {
        this.loaderService.loading$.subscribe((isLoading) => {
            this.isLoading = isLoading;
        });

        this.dataSharingService.searchData$.subscribe((searchData) => {
            this.searchData = searchData;
        });
    }

    onTabChange(event: MatTabChangeEvent): void {
        console.log('Onglet modifié : ', event.index);
        this.searchData = {}
    }

    handlePageEvent(e: PageEvent) {
        let searchString = '';

        this.pageEvent = e;
        this.pageIndex = e.pageIndex;
        // const pageParams = new URLSearchParams();
        // pageParams.set('page', (this.pageIndex + 1).toString());

        // const searchParams = new URLSearchParams(this.params.toString());
        // console.log(searchString);
        // searchParams.delete('page');

        // searchParams.append(pageParams); // Ajoute le paramètre de page à la chaîne de requête principale

        // console.log(this.pageIndex);

        searchString = '?' + this.params.set('page', (this.pageIndex + 1).toString());
        console.log(searchString);

        // this.find(search);
    }

    search() {
        let searchString = '';
        if (this.searchData?.name) {
            this.params.set('name', this.searchData.name);
        }
        if (this.searchData?.zone) {
            this.params.set('zone', this.searchData.zone);
        }
        if (this.searchData?.status) {
            this.params.set('status', this.searchData.status);
        }
        if (this.searchData?.gps_position) {
            this.params.set('gps_position', this.searchData.gps_position);
            this.notificationService.showWarning('La recherche sur la position n\'est pas encore prise en compte');
        }
        searchString = '?' + this.params.toString();
        console.log(searchString);

        this.find(searchString)
    }



    find(searchString: string) {
        searchString = searchString + '&is_client=true'; // Important pour differencier les clients pdv des grans master partenaires qui sont genéré automatiquement
        console.log(searchString);
        this.salepointService.search(searchString).pipe(
            tap((reponse) => {
                this.length = reponse.meta.total;
                this.pageSize = reponse.meta.per_page;
                this.foundSalepoints = reponse.data
                this.notificationService.showSuccess("Résultat : " + reponse.data.length)
            }),
            catchError((error) => { throw error })
        ).subscribe()
    }
}
