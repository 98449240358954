<app-header></app-header>
<div class="container" style="margin-top: 6rem;">

	<div class="col-md-8 col-sm-12 offset-md-2">
		<div class="card shadow-custom">
			<div class="position-relative">
				<img [src]="cacheSalepoint.photo_front" width="100%" style="max-height: 500px; min-height: 400px;"
					class="card-img-top" alt="Photo de pdv non fournie" />
				<div class="position-absolute" *ngIf="cacheSalepoint.is_master" style="top: 1%; left: 1%;"
					matTooltip="Master">
					<button mat-mini-fab color="primary">
						<mat-icon>account_balance</mat-icon>
					</button>
				</div>
				<div class="position-absolute" *ngIf="cacheSalepoint.is_uveon_master" style="top: 1%; left: 10%;"
					matTooltip="Master Uveon">
					<button mat-mini-fab color="success">
						<mat-icon>account_balance</mat-icon>
					</button>
				</div>

				<div class="position-absolute" style="top: 1%; right: 1%;" *ngIf="cacheSalepoint.status != 'partner'">
					<div class="badge rounded text-start bg-white badge-shadow px-3 py-2">
						<mat-icon class="text-success" style="vertical-align: bottom;">check_circle</mat-icon>
						<span class="text-dark" style="vertical-align: super;">2/2</span>
					</div>
				</div>

				<div class="position-absolute" *ngIf="cacheSalepoint.status != 'partner'" style="bottom: 0; left: 1%;">
					<span class="badge rounded text-start badge-shadow text-bg-danger px-3 py-2">
						Revue par Albert <br>
						10-10-2024 à 17h20
					</span>
				</div>

				<div class="position-absolute" *ngIf="cacheSalepoint.status" style="bottom: 0; right:0;">
					<span [ngClass]="getClassFromStatus(cacheSalepoint.status)"
						class="badge rounded text-start badge-shadow px-3 py-2">
						{{libelleFromStatus(cacheSalepoint.status)}}
					</span>
				</div>
			</div>
			<h5 class="card-title text-center mt-5">{{cacheSalepoint.name}}</h5>
			<hr>
			<p class="card-text text-center">Gerant: {{cacheSalepoint.manager ?? "Aucun gérant"}}</p>
			<hr>
			<h6 class="inform"> {{cacheSalepoint.zone}} </h6>
			<h6 class="inform"> {{cacheSalepoint.street}} </h6>
			<h6 class="inform"> {{cacheSalepoint.gps_position}} </h6>
			<div class="row">
				<div class="col text-center">
					{{activate ? "Désactiver" : "Activer" }} <br>
					<mat-slide-toggle (change)="updateChecked()" color="primary"
						[checked]="cacheSalepoint.verified"></mat-slide-toggle>
					<!-- <mat-slide-toggle color="primary" (change)="updateVerified()" [checked]="cacheSalepoint.verified"></mat-slide-toggle> -->
				</div>
				<div class="col text-center">
					Rechercher <br>
					<button mat-fab color="basic">
						<mat-icon>search</mat-icon>
					</button>
				</div>
				<div class="col text-center">
					Google Maps <br>
					<button mat-fab color="basic">
						<mat-icon>location_searching</mat-icon>
					</button>

				</div>
			</div>

			<div class="card-body mt-2">
				<div class="titleType mb-3">
					<h5 class="text-center"> Activités du point de vente</h5>
				</div>
				<div class="navbar">
					<div class="nav-item" (click)="selectInterval(7)" [ngClass]="{active: interv===7}">7 jours</div>
					<div class="nav-item" (click)="selectInterval(30)" [ngClass]="{active: interv===30}">30 jours</div>
					<div class="nav-item" (click)="selectInterval(90)" [ngClass]="{active: interv===90}">90 jours</div>
					<div class="nav-item" (click)="selectInterval(180)" [ngClass]="{active: interv===180}">180 jours
					</div>
				</div>

				<div class="interface mb-5">
					<div class="row">
						<div *ngFor="let stat of statistics" class="col-md-4 text-center">
							<h5 *ngIf="stat.label !== 'Taux de satisfaction'" class="title">{{stat.label}}</h5>
							<div *ngIf="stat.label !== 'Taux de satisfaction'">
								{{stat.data}}
							</div>
						</div>

					</div>

					<div class="row" *ngFor="let stat of statistics">
						<div *ngIf="stat.label === 'Taux de satisfaction'" class="col text-center">
							<h5 class="title">{{stat.label}}</h5>
							<div>
								{{stat.data}}
							</div>
						</div>
					</div>
				</div>

				<div class=" d-flex justify-content-center align-items-center"
					style="width: 100%; margin-bottom: 35px;">

					<div style="margin-right: 25px;">
						<div>
							<p class="idManager">{{manager ? manager.id : "Id du gérant"}} </p>
						</div>

						<div>
							<img id="place"
								src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
								width="150" height="150" alt="photo" class="mt-2">
						</div>

					</div>

					<div style="margin-left: 25px;">
						<div>
							<p class="managerInfo">{{manager ? manager.last_name : "Nom"}}</p>
						</div>
						<div>
							<p class="managerInfo">{{manager ? manager.name : "Prénoms"}} </p>
						</div>
						<div>
							<p class="managerInfo">{{manager ? manager.tel : "Numero de téléphone"}}</p>
						</div>
						<p class="btnmanager" (click)="chooseManager()" style="cursor: pointer;">
							Changer Gerant
						</p>

					</div>
				</div>


				<div class="titleType">
					<h5 class="text-center"> Configuration Agence</h5>
				</div>
				<div class="col-md-12  col-sm-12 mt-4">

					<div class="fond">
						<mat-radio-group [(ngModel)]="agenceType">
							<div>
								<mat-radio-button color="primary" (click)="updateType('Box ou Cabine')"
									value="Box ou Cabine">
									Box ou Cabine
								</mat-radio-button>
							</div>
							<div>
								<mat-radio-button
									(click)="updateType('Comptoir dans un magasin sans guichet ni grille')"
									value="Comptoir dans un magasin sans guichet ni grille" color="primary">
									Comptoir dans un magasin sans guichet ni grille
								</mat-radio-button>
							</div>
							<div>
								<mat-radio-button (click)="updateType('Agence avec guichet et grille')"
									value="Agence avec guichet et grille" color="primary">
									Agence avec guichet et grille
								</mat-radio-button>
							</div>
							<div>
								<mat-radio-button (click)="updateType('Agence sécurisée avec caméra')"
									value="Agence sécurisée avec caméra" color="primary">
									Agence sécurisée avec caméra
								</mat-radio-button>
							</div>
							<div>
								<mat-radio-button (click)="updateType('Agence sécurisée avec caméra et virgile')"
									value="Agence sécurisée avec caméra et virgile" color="primary">
									Agence sécurisée avec caméra et virgile
								</mat-radio-button>
							</div>
						</mat-radio-group>




					</div>
				</div>


				<!-- <ng-template *ngIf="managerList.length > 0"> -->
				<hr>
				<div class="row">
					<div class="titleType">
						<h5 class="text-center"> Opérateur mobile money/Finetech </h5>
					</div>

					<div>
						<mat-checkbox color="primary">Orange Money</mat-checkbox>
					</div>
					<div>
						<mat-checkbox color="primary">MTN Money</mat-checkbox>
					</div>
					<div>
						<mat-checkbox color="primary">Moov Money</mat-checkbox>
					</div>
					<div>
						<mat-checkbox color="primary">Wave</mat-checkbox>
					</div>
					<div>
						<mat-checkbox color="primary">Push</mat-checkbox>
					</div>
					<div>
						<mat-checkbox color="primary">UBA</mat-checkbox>
					</div>

				</div>

				<hr>
				<div class="row mb-5">
					<div class="titleType">
						<h5 class="text-center">
							Agents liés au point de vente
						</h5>
					</div>
					<h5></h5>
					<ng-container *ngFor="let manager of managerAcceptedList" >
						<div 
						class="d-flex justify-content-between my-1 line" 
						style="background-color: #f4f4f4; 
					   padding: 10px; cursor: pointer;"
					   (click)="onManagerDetail(manager)" >
						   <div><mat-icon class="fs-4">account_circle</mat-icon>
								{{manager.user}}
						</div>
						   <div>{{manager.type}} </div>
						   <div>
							   <mat-icon>folder</mat-icon>
							   <mat-icon>phone</mat-icon>
						   </div>
					   </div>
					</ng-container>
					
				</div>

				<div class="titleType">
					<h5 class="text-center"> Rejoindre mon Agence </h5>
				</div>
				<table class="table table-striped">
					<thead>
						<tr>
							<th scope="col">Nom</th>
							<th scope="col">role</th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of managerList">
							<th scope="row">{{item.user}}</th>
							<td>{{item.type}}</td>
							<td>
								<button (click)="rejectManager(item)" mat-mini-fab color="warn"
									matTooltip="Supprimer la demande">
									<mat-icon>delete</mat-icon>
								</button>

								<button (click)="acceptManager(item)" mat-mini-fab class="bg-success m-1"
									matTooltip="Accepter la demande">
									<mat-icon>done</mat-icon>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- </ng-template> -->

				<hr>
				<div class="row">
					<div class="titleType">
						<h5 class="text-center"> Autorisation service </h5>
					</div>

					<div>
						<mat-checkbox color="primary">Achat</mat-checkbox>
					</div>
					<div>
						<mat-checkbox color="primary">Vente directe</mat-checkbox>
					</div>
					<div>
						<mat-checkbox color="primary">Appro Cash</mat-checkbox>
					</div>
					<div>
						<mat-checkbox color="primary">Soutrali 24H</mat-checkbox>
					</div>

				</div>
				<div class="row mt-3" *ngIf="achatUveList.length > 0">
					<h5>Autorisation Niveau</h5>

					<div class="titleType">
						<h5 class="text-center">{{achatUveList[0].type}}</h5>
					</div>
					
					<mat-radio-group [(ngModel)]="achatUveLevelId">
						<div *ngFor="let achat of achatUveList">
							<mat-radio-button (click)="updatePDVLevels(achat.id, 'achat')" [value]="achat.id" color="primary"><b>{{achat.code}}: {{achat.label}} </b>
							</mat-radio-button>
						</div>
					</mat-radio-group>
					<div>
						<p class="text-danger text-decoration-underline">
							NB: Les cabinars et les pdv sans KYC sont limités au <b>Niveau 1</b>
						</p>
					</div>
				</div>

				<div class="row" *ngIf="venteUveList.length > 0">
					<div class="titleType">
						<h5 class="text-center">{{venteUveList[0].type}}</h5>
					</div>
					<h5></h5>
					<mat-radio-group [(ngModel)]="venteUveLevelId">
						<div *ngFor="let vente of venteUveList">
							<mat-radio-button (click)="updatePDVLevels(vente.id, 'vente')" [value]="vente.id" color="primary"><b>{{vente.code}}: {{vente.label}} </b>
							</mat-radio-button>
						</div>
					</mat-radio-group>
					<div>
						<p class="text-danger text-decoration-underline">
							NB: Les cabinars et les pdv sans KYC ne sont pas éligibles au service
						</p>
					</div>
				</div>

				<div class="row" *ngIf="approCashList.length > 0">
					<div class="titleType">
						<h5 class="text-center">{{approCashList[0].type}}</h5>
					</div>

					<mat-radio-group [(ngModel)]="approCashLevelId">
						<div *ngFor="let appro of approCashList">
							<mat-radio-button (click)="updatePDVLevels(appro.id, 'appro')" [value]="appro.id" color="primary"><b>{{appro.code}}: {{appro.label}} </b>
							</mat-radio-button>
						</div>
					</mat-radio-group>

					<div>
						<p class="text-danger text-decoration-underline">
							NB: Les cabinars et les pdv sans KYC ne sont pas éligibles au service
						</p>
					</div>
				</div>

				<div class="row" *ngIf="soutrali24HList.length > 0">
					<div class="titleType">
						<h5 class="text-center">{{soutrali24HList[0].type}}</h5>
					</div>

					<mat-radio-group [(ngModel)]="soutrali24HLevelId">
						<div *ngFor="let soutra of soutrali24HList">
							<mat-radio-button (click)="updatePDVLevels(soutra.id, 'soutra')" [value]="soutra.id" color="primary"><b>{{soutra.code}}: {{soutra.label}} </b>
							</mat-radio-button>
						</div>
					</mat-radio-group>
					<div>
						<p class="text-danger text-decoration-underline">
							NB: Les cabinars et les pdv sans KYC ne sont pas éligibles au service
						</p>
					</div>
				</div>
				<hr>
				<div class="row">
					<div class="titleType">
						<h5 class="text-center">Horaires :<button class="ms-3" (click)="allowScheUpdate()" mat-mini-fab
								color="primary" matTooltip="Modifier">
								<mat-icon>edit</mat-icon>
							</button></h5>

					</div>

					<div class="d-flex justify-content-evenly mb-1 mt-3" style="color:#19265D">
						<div>Jour</div>
						<div>Ouverture</div>
						<div>Fermeture</div>
					</div>

					<form [formGroup]="SheduleForm">

						<div class="opening-hours" formArrayName="Hours">
							<div class="day" *ngFor="let hour of Hours.controls; let i = index" [formGroupName]="i">

								<input type="text" formControlName="day" readonly>&nbsp;
								<div class="input-container">

									<input style="cursor: pointer;" [ngxTimepicker]="pickerA" formControlName="ouverture" [format]="24"
										readonly />

									<ngx-material-timepicker #pickerA></ngx-material-timepicker>


									<input style="cursor: pointer;" [ngxTimepicker]="pickerB " formControlName="fermeture" [format]="24"
										readonly />

									<ngx-material-timepicker #pickerB></ngx-material-timepicker>
								
								</div>
								<!--<button (click)="deleteShedule(i)" mat-mini-fab color="warn"
									aria-label="Example icon button with a delete icon">
									<mat-icon>delete</mat-icon>
								</button> -->
							</div>
						</div>

						<div class="mb-5 d-flex justify-content-center" *ngIf="editSched">
							<button class="btn" (click)="registerShedule()" type="submit">Valider</button>

						</div>
					</form>

				</div>

				<div class=" mt-5">
					<div class="titleType">
						<h5 class="text-center">Comptes liés au point de vente :
							<button class="ms-3" (click)="addAccount()" mat-mini-fab color="primary"
								matTooltip="Ajouter">
								<mat-icon>add</mat-icon>
							</button>
						</h5>
					</div>

					<div class="fond" style="height: 40vh; overflowY: scroll; overflowX: hidden">
						<table class="table tab" *ngIf="accountsList.length!==0">
							<tr *ngFor="let account of CopyaccountsList; ">
								<td><img width="50px" height="50px" style="border-radius: 50px;"
										[src]="showLogo(account.operateur)" alt="Image.nom" class="image"> </td>
								<td>{{account.operateur}} </td>
								<td>{{account.master_name}} </td>
								<td>{{account.tel}}</td>
								<td><i (click)="deletePDV(account)" class="bi bi-trash-fill del"></i></td>
							</tr>

						</table>
					</div>
				</div>
				<div class="row">
					<div class="titleType">
						<h5 class="text-center">KYC</h5>
					</div>

					<div class="d-flex justify-content-arround align-items-center align-items-center">
						<div><b>
								CNI - Carte nationale d'identité
							</b></div>
						<div style="margin-left:15px">
							<mat-checkbox [disabled]="true" color="primary"
								[checked]="isCNI"></mat-checkbox>
						</div>
					</div>
					<div class="d-flex justify-content-arround align-items-center">
						<div><b>
								RCCM - Registre du commerce
							</b></div>
						<div style="margin-left:15px">
							<mat-checkbox [disabled]="true" color="primary"
								[checked]="isRCCM"></mat-checkbox>
						</div>
					</div>
					<div class="d-flex justify-content-arround align-items-center">
						<div><b>
								DFE - Déclaration fiscale d'existence
							</b></div>
						<div style="margin-left:15px">
							<mat-checkbox [disabled]="true" color="primary"
								[checked]="isDFE"></mat-checkbox>
						</div>
					</div>
					<div class="d-flex justify-content-arround align-items-center">
						<div><b>
								Contrat de bail du local ou facture CIE ou SODECI
							</b></div>
						<div style="margin-left:15px">
							<mat-checkbox [disabled]="true" color="primary"
								[checked]="isBail"></mat-checkbox>
						</div>
					</div>
					<div class="d-flex justify-content-evenly mb-2 mt-2"
						style="background-color: #f4f4f4; padding: 10px;">
						<h4>Documents KYC et autres</h4>
						<button class="ms-3" (click)="addFile()" mat-mini-fab color="primary" matTooltip="Ajouter">
							<mat-icon>add</mat-icon>
						</button>
					</div>

				</div>
				<hr>
				<div class="map-container mb-3">
					<google-map [options]="mapOptions" width="100%" height="400px">
						<map-marker [position]="markerPosition"></map-marker>
					</google-map>
				</div>

				<div class="row">
					<div class="titleType mb-4">
						<h5 class="text-center">Commentaire</h5>
					</div>

					<div class="comment">
						<input type="text" [(ngModel)]="message" name="" id="" class="commentText"
							placeholder="saisissez votre commentaire">
					</div>
					<div class="btnCom mb-3">
						<button (click)="addComment()" class="btn">Ajouter</button>
					</div>

					<div *ngIf="commentList">
						<div *ngFor="let comment of commentList">
							<div class="mb-3" style="background-color: #f4f4f4; padding: 10px; color: #19265D;">
								<h6 style="font-weight: 100; font-size: 20px;">{{comment.message}}</h6>
								<div class="d-flex justify-content-between">
									<small><b>{{commentAuthor(comment.user_id)}}</b> </small>
									<small><b>{{comment.created_at }}</b> </small>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p class="card-text">
					<small class="text-muted">Créé le: {{ cacheSalepoint.created_at | date:'dd MMMM yyyy à HH:mm'
						}}</small>
					<small class="text-muted"> Par: {{ cacheSalepoint.createdBy }}</small>
				</p>
			</div>
		</div>
	</div>

</div>