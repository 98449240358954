import { Component } from '@angular/core';

@Component({
    selector: 'app-affiche',
    templateUrl: './affiche.component.html',
    styleUrls: ['./affiche.component.scss']
})
export class AfficheComponent {
    registerFile?: File;
    titre?:string;
    content?:string;
    userFilter?:string;

    constructor() {}

    openFileExplorer(content: string) {
    document.getElementById(content)!.click();
    }

    onFileSelected(event: Event, placeholder: string, lab: string) {
        let file
        const inputElement = event.target as HTMLInputElement;
        const fileNameSpan = document.getElementById(placeholder);
        const label = document.getElementById(lab);
        if (inputElement.files && inputElement.files.length > 0) {
            const fileName = inputElement.files[0].name;
            file = inputElement.files[0]
            this.addfileName(fileNameSpan as HTMLElement, label as HTMLElement, fileName)
        }
        return file
    }

    addfileName(fileNameSpan: HTMLElement | null, label: HTMLElement | null, fileName: string) {

        if (fileNameSpan && label) {
            fileNameSpan.textContent = fileName;
            label.textContent = ""
        }
        else {
            if (fileNameSpan) {
                fileNameSpan.textContent = '';
            }
        }
    }

    onRegisterFile(event: Event, placeholder: string, lab: string) {

        this.registerFile = this.onFileSelected(event, placeholder, lab)
    }

    CreateAffiche() {
        const data = new FormData();
        data.append('image', this.registerFile!);
        data.append('titre', this.titre!);
        data.append('contenu', this.content!);
        data.append('user_filter', this.userFilter!);
    }
}
