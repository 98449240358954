import { Component, OnInit } from '@angular/core'
import { AuthService } from './api/auth.service';
import { Router } from '@angular/router';
import { WebSocketService } from './api/ws/WebSocket.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private router: Router,
        private webSocketService: WebSocketService
        // private socket: Socket
    ) { }

    ngOnInit(): void {
        // Envoyer un message au serveur
        this.webSocketService.sendMessage({ type: 'message', content: 'Hello, WebSocket!' });

        // Écouter les messages du serveur
        this.webSocketService.getMessage().subscribe((message) => {
            console.log('Message du serveur:', message);
        });
        // this.socket.connect();

        // this.socket.on('notifications', (data: any) => {
        //     console.log(data);
        // });

        // this.socket.on('message', (data: any) => {
        //     console.log('Message reçu du backend:', data);
        // });

        // setInterval(() => {
        //     this.authService.refreshToken().pipe(
        //         tap((resp) => {
        //             console.log(resp)

        //         }),
        //         catchError((error) => {
        //             localStorage.clear();
        //             this.router.navigateByUrl('/connexion');

        //             console.log(error)
        //             throw error;
        //         })
        //     ).subscribe();
        // },1800000) //todo: 30 minutes mais cette valeure doit etre dynamique en fonction du back
    }
}