import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommunicationComponent } from './communication.component';
import { AfficheComponent } from './affiche/affiche.component';
import { BanniereComponent } from './banniere/banniere.component';
import { OptionsComponent } from './options/options.component';

const routes: Routes = [
    { path: 'communication', component: CommunicationComponent,children: [
        { path: '', component: OptionsComponent },
        { path: 'affiche', component: AfficheComponent },
        { path: 'banniere', component: BanniereComponent }
    ] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class communicationRoutingModule { }
