import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { catchError, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/api/notification.service';
import { IRoles } from 'src/app/Types/roles';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { IForfait } from 'src/app/Types/forfait';
import { ForfaitService } from 'src/app/api/forfait.service';
import { AddForfaitDialogComponent } from '../../dialogs/add-forfait-dialog/add-forfait-dialog.component';
@Component({
    selector: 'app-forfait',
    templateUrl: './forfait.component.html',
    styleUrls: ['./forfait.component.scss']
})
export class ForfaitComponent implements OnInit {

    dataSource: IForfait[] = [];
    forfaits: IForfait[] = [];
    title: string = '';
    roles: IRoles[] = [];
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

    constructor(
        private forfaitService: ForfaitService,
        public dialog: MatDialog,
        public notificationService: NotificationService,
    ) { }

    ngOnInit(): void {
        this.forfaitService.getFofaits().pipe(
            tap((response) => {
                this.title = 'Liste des forfaits';
                this.dataSource = response.data
                this.forfaits = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()
    }

    addForfait() {
        const dialogRef = this.dialog.open(AddForfaitDialogComponent, {
            width: '50%',
            height: '55%'
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.ngOnInit();
        });
    }


    delete(forfait: IForfait) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
            {
                width: '25%',
                data: {
                    id: forfait.id,
                    class: 'bg-danger text-white text-bold',
                    message: 'Suppression du forfait ' + forfait.title
                }
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.forfaitService.deleteforfait(forfait.id!).pipe(
                    tap((response) => {
                        this.notificationService.showSuccess(response.message);
                        this.ngOnInit();
                    }),
                    catchError((fail) => {
                        this.notificationService.showError(fail.error.message);
                        throw fail;
                    })
                ).subscribe()

            } else {
                return;
            }
        });

    }

    edit(forfait: IForfait) {
        console.log(forfait);
        const dialogRef = this.dialog.open(
            AddForfaitDialogComponent,
            {
                width: '50%',
                height: '55%',
                data: forfait
            });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.ngOnInit();
        });
    }
}