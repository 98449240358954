import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { IUsers } from 'src/app/Types/users';
import { UserService } from 'src/app/api/user.service';
import { catchError, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddUserDialogComponent } from '../../dialogs/add-user-dialog/add-user-dialog.component';
import { NotificationService } from 'src/app/api/notification.service';
import { RoleService } from 'src/app/api/role.service';
import { IRoles } from 'src/app/Types/roles';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    displayedColumns: string[] = ['#', 'name', 'tel'];
    columnsToDisplayWithOptions = [...this.displayedColumns, 'groupe', 'options'];
    dataSource: IUsers[] = [];
    users: IUsers[] = [];
    title: string = '';
    roles: IRoles[] = [];
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

    constructor(
        private userService: UserService,
        public dialog: MatDialog,
        public notificationService: NotificationService,
        private rolesService: RoleService,
    ) { }

    ngOnInit(): void {
        this.userService.getAll().pipe(
            tap((response) => {
                this.title = 'Liste des utilisateurs';
                this.dataSource = response.data
                this.users = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()

        this.getRoles();
    }




    getAgent() {
        this.userService.search('?role_id=6').pipe(
            tap((response) => {
                this.title = 'Agents';
                this.dataSource = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()
    }

    getOperateur() {
        this.userService.search('?role_id=5').pipe(
            tap((response) => {
                this.title = 'Operateurs';
                this.dataSource = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()
    }

    getLead() {
        this.userService.search('?role_id=5').pipe(
            tap((response) => {
                this.title = 'Lead';
                this.dataSource = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()
    }

    getBasics() {
        this.userService.search('?role_id=2').pipe(
            tap((response) => {
                this.title = 'Basics';
                this.dataSource = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()
    }

    getRoles() {
        this.rolesService.getAll().pipe(
            tap((response) => {
                this.roles = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()
    }

    getRoleLabel(roleId: number): string {
        const role = this.roles.find(r => r.id === roleId);
        return role ? role.name : '';
    }

    addUser() {
        const dialogRef = this.dialog.open(AddUserDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.ngOnInit();
        });
    }

    getTrashedUsers() {
        this.userService.getAllDeleted().pipe(
            tap((response) => {
                this.title = 'Corbeille';
                this.dataSource = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()
    }

    delete(user: IUsers) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
            {
                width: '25%',
                data: {
                    id: user.id,
                    class: 'bg-danger text-white text-bold',
                    message: 'Suppression de ' + user.name + ' ' + user.last_name
                }
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.userService.delete(user.id!).pipe(
                    tap((response) => {
                        this.notificationService.showSuccess(response.message);
                        this.ngOnInit();
                    }),
                    catchError((fail) => {
                        this.notificationService.showError(fail.error.message);
                        throw fail;
                    })
                ).subscribe()
                
            } else{
                return;
            }
        });


    }

    edit(user: IUsers) {
        console.log(user);
        const dialogRef = this.dialog.open(
            AddUserDialogComponent,
            { data: user });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.ngOnInit();
        });
    }

    filter(roleCode: string) {
        const currentRole = this.roles.find(role => role.code === roleCode)
        this.title = currentRole?.name ?? ""
        const filterUser = this.users.filter(user => user.role_id === currentRole?.id)
        this.dataSource = filterUser
    }
}