import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SalepointService } from 'src/app/api/salepoint.service';
import { catchError, tap } from 'rxjs';
import { NotificationService } from 'src/app/api/notification.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-add-doc-dialog',
    templateUrl: './add-doc-dialog.component.html',
    styleUrls: ['./add-doc-dialog.component.scss']
})
export class AddDocDialogComponent {

    registerFile?: File
    pdvId?: number
    docForm: UntypedFormGroup;
    docType: any[] = [
        { name: "Régistre du commerce", value: "commercial_register" },
        { name: "Déclaration Fiscale d’existence", value: "tax_exist" },
        { name: "Contrat de bail du local ou facture CIE ou SODECI", value: "lease_contract" },
        { name: "Recto de la pièce", value: "manager_front_photo" },
        { name: "Verso de la pièce", value: "manager_back_photo" },
    ]


    constructor(private pdvService: SalepointService,
        private notificationService: NotificationService,
        private fb: UntypedFormBuilder,
        public matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private datePipe: DatePipe

    ) {
        this.pdvId = data.id

        this.docForm = this.createDocForm()


    }


    openFileExplorer(content: string) {
        document.getElementById(content)!.click();
    }
    onRegisterFile(event: Event, placeholder: string, lab: string) {

        this.registerFile = this.onFileSelected(event, placeholder, lab)
    }
    onFileSelected(event: Event, placeholder: string, lab: string) {
        let file
        const inputElement = event.target as HTMLInputElement;
        const fileNameSpan = document.getElementById(placeholder);
        const label = document.getElementById(lab);
        if (inputElement.files && inputElement.files.length > 0) {
            const fileName = inputElement.files[0].name;
            file = inputElement.files[0]
            this.addfileName(fileNameSpan as HTMLElement, label as HTMLElement, fileName)
        }
        return file
    }

    addfileName(fileNameSpan: HTMLElement | null, label: HTMLElement | null, fileName: string) {

        if (fileNameSpan && label) {
            fileNameSpan.textContent = fileName;
            label.textContent = ""
        }
        else {
            if (fileNameSpan) {
                fileNameSpan.textContent = '';
            }
        }
    }


    createDocForm(): UntypedFormGroup {
        return this.fb.group({
            fileType: ['', Validators.required],
            endDate: ['', Validators.required]

        })
    }


    transformDate(dateString: string): string {
        const date = new Date(dateString);
        return this.datePipe.transform(date, 'yyyy-MM-dd')!;
    }

    updateDocSalePoint() {
        const formdata = this.docForm.getRawValue()

        const data = new FormData();

        data.append(formdata.fileType, this.registerFile!);
        data.append(formdata.fileType + '_end_date', this.transformDate(formdata.endDate))

        this.pdvService.updatePDVFinal(data, this.pdvId as number).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.docForm.reset()
                this.registerFile = undefined
            }),
            catchError((errorMessage) => {
                this.docForm.reset()
                this.registerFile = undefined
                this.notificationService.showError(errorMessage);
                throw errorMessage;

            })
        ).subscribe();



    }
}
