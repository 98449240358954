<h3> {{title}} </h3>

<div class="row py-3 mb-3">

    <button mat-fab extended color="primary" (click)="addNewNum()">
        <mat-icon>add_circle</mat-icon>
        Ajouter un numéro
    </button>



</div>


<p-table *ngIf="customerstels.length!=0" #dt styleClass="p-datatable-sm " [value]="dataSource" responsiveLayout="scroll" [rows]="10"
    [globalFilterFields]="['No', 'nom']" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 20, 30]"
    [showCurrentPageReport]="true" currentPageReportTemplate="De {first} à {last} sur {totalRecords}"
    selectionMode="single" [rowHover]="true" dataKey="id">
    <!-- <ng-template pTemplate="caption">
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="onGlobalFilter(dt, $event)"
                                    placeholder="Rechercher..." class="w-full sm:w-auto" />
                            </span>
                        </div>
                    </ng-template> -->
    <ng-template pTemplate="header">
        <tr>
            <th class="head" pSortableColumn="No">
                Type de contact
                <p-sortIcon field="No"></p-sortIcon>
            </th>

            <th class="head" pSortableColumn="nom">
               Icône
                <p-sortIcon field="nom"></p-sortIcon>
            </th>
            <th class="head" pSortableColumn="tel">
                Numéro de téléphone
                <p-sortIcon field="tel"></p-sortIcon>
            </th>
            
            <th class="head" pSortableColumn="Validation">
                Action
                <p-sortIcon field="Validation"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element>
        <tr class="line" (click)="edit(element)">
            <td style="width: 20%; ">
                <span class="p-column-title">No</span>
                {{ element.type }}
            </td>

            <td style="width: 20%; ">
                <span class="p-column-title">Nom et prénoms</span>
                {{element.icon}}
                
            </td>
            <td style="width: 20%; ">
                <span class="p-column-title">tel</span>
                {{element.tel}}
            </td>
           


            <td (click)="$event.stopPropagation()" style="width: 10%; ">


                <mat-icon [matMenuTriggerFor]="menu" aria-hidden="false">more_vert</mat-icon>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="delete(element)">Supprimer</button>
                </mat-menu>

                <!-- <p-checkbox (click)="onActivateMatiere(element)" [(ngModel)]="element.Activated" [binary]="true" inputId="binary"></p-checkbox> -->
            </td>

        </tr>
    </ng-template>
</p-table>

<div class="spinner-container" *ngIf="customerstels.length===0">

    <mat-progress-spinner *ngIf="customerstels.length===0" [diameter]="50"
        mode="indeterminate"></mat-progress-spinner>

</div>