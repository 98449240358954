import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchPdvComponent } from './search-pdv.component';
import { AuthGuard } from 'src/app/api/guards/auth.guard';
import { SearchDetailComponent } from './search-detail/search-detail.component';

const routes: Routes = [
    { path: 'search', component: SearchPdvComponent, canActivate: [AuthGuard] },
    { path: 'search/detail', component: SearchDetailComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SearchPdvRoutingModule { }
