import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SalepointService } from 'src/app/api/salepoint.service';
import { NotificationService } from 'src/app/api/notification.service';

@Component({
    selector: 'app-banniere',
    templateUrl: './banniere.component.html',
    styleUrls: ['./banniere.component.scss']
})
export class BanniereComponent {
    registerFile?: File;
    
    userFilter?:string;

    constructor(
      private pdvService: SalepointService,
      private notificationService: NotificationService,
      private route: Router
    ) {}

    openFileExplorer(content: string) {
    document.getElementById(content)!.click();
    }

    onFileSelected(event: Event, placeholder: string, lab: string) {
        let file
        const inputElement = event.target as HTMLInputElement;
        const fileNameSpan = document.getElementById(placeholder);
        const label = document.getElementById(lab);
        if (inputElement.files && inputElement.files.length > 0) {
            const fileName = inputElement.files[0].name;
            file = inputElement.files[0]
            this.addfileName(fileNameSpan as HTMLElement, label as HTMLElement, fileName)
        }
        return file
    }

    addfileName(fileNameSpan: HTMLElement | null, label: HTMLElement | null, fileName: string) {

        if (fileNameSpan && label) {
            fileNameSpan.textContent = fileName;
            label.textContent = ""
        }
        else {
            if (fileNameSpan) {
                fileNameSpan.textContent = '';
            }
        }
    }

    onRegisterFile(event: Event, placeholder: string, lab: string) {

        this.registerFile = this.onFileSelected(event, placeholder, lab)
    }

    

 
    CreateBanniere() {
    
        const data = new FormData();
        data.append('image', this.registerFile!);
        data.append('user_filter', this.userFilter!);
      

        // if (!this.cniBack || !this.cniFront) {
        //     this.notificationService.showError("Veuillez ajouter les documents du gérant");
        // } 
        // else {
        //     this.pdvService.updatePDVFinal(data, String(this.pdvId)).pipe(
        //         tap((response) => {
        //             this.notificationService.showSuccess(response.message);
        //             this.next()
        //             this.route.navigateByUrl('/pdv-form/success-page')

        //         }),
        //         catchError((errorMessage) => {
        //             this.notificationService.showError(errorMessage);
        //             throw errorMessage;
        //         })
        //     ).subscribe();
        // }




    }

}
