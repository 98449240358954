<h3 mat-dialog-title class="text-bold text-center">{{sendTitle}} numéro</h3>

<mat-dialog-content class="custom-dialog-width">

	<form [formGroup]="substelForm">
		<div class="form-container">
			<div class="form-row">
			  <div class="form-field col-12 col-md-12">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
					<mat-label>Operateur</mat-label>
					<mat-select formControlName="operateur" >
						<mat-option *ngFor="let op of operateurs" [value]="op.name">
							{{op.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			  </div>
			</div>
			 
			<div class="form-row">
			  <div class="form-field col-12 col-md-12">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
				  <mat-label>Numéro de téléphone</mat-label>
				  <input formControlName="tel" type="text" matInput>
				</mat-form-field>
			  </div>
			</div>
		  
			
		  </div>
		  
	</form>
<mat-dialog-content>

<mat-dialog-actions class="justify-content-evenly">
	<button class="mb-2" mat-raised-button (click)="matDialogRef.close(false)">
		Annuler
	</button>
	
	<button mat-raised-button color="primary" type="submit" [disabled]="substelForm.invalid || lockDuringSending"
		(click)="send()">
		{{sendTitle}}
	</button>
</mat-dialog-actions>