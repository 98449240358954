import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStorageService } from './helpers/app-storage.service';
import { BaseRepository } from './base/base-repository';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CommentService extends BaseRepository {
    constructor(http: HttpClient, appStorageService: AppStorageService) {
        super('comments', http, appStorageService);
    }

    getComments(salepoint_id: number) {
        return this.http.get<any>(this.url+ 'salepoints/' + salepoint_id + "/" + this.controllerName, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    createComment(salepoint_id: number, mess: string) {
        const data = {
            message: mess,
            salepoint_id : salepoint_id,
        }
        return this.http.post<any>(this.url + this.controllerName, data).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }
}
