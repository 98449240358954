import { ManagerDetailDialogComponent } from './../../dialogs/manager-detail-dialog/manager-detail-dialog.component';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs';
import { ISalepoint } from 'src/app/Types/salepoints';
import { ManagerService } from 'src/app/api/manager.service';
import { SalepointService } from 'src/app/api/salepoint.service';
import { HelperFunctions } from 'src/app/helpers/helpers';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IOperateur } from 'src/app/Types/operateurs';
import { SettingsService } from 'src/app/api/setting.service';
import { AddAccountDialogComponent } from '../../dialogs/add-account-dialog/add-account-dialog.component';
import { AddDocDialogComponent } from '../../dialogs/add-doc-dialog/add-doc-dialog.component';
import { NotificationService } from 'src/app/api/notification.service';
import { StatService } from 'src/app/api/statistique.service';
import { CommentService } from 'src/app/api/comment.service';
import { UserService } from 'src/app/api/user.service';
import { LevelService } from 'src/app/api/level.service';
import { ChooseManagerDialogComponent } from '../../dialogs/choose-manager-dialog/choose-manager-dialog.component';

@Component({
    selector: 'app-search-detail',
    templateUrl: './search-detail.component.html',
    styleUrls: ['./search-detail.component.scss']
})
export class SearchDetailComponent {
    @ViewChild(MatSlideToggle) slideToggle: MatSlideToggle | undefined;
    agenceType?: string
    editSched: boolean = false
    cacheSalepoint: ISalepoint = {}
    selectedInterval: string = '';
    activate: boolean = false
    salepoint: ISalepoint = {};
    managerList: any[] = [];
    managerAcceptedList: any[] = [];
    manager?: any;
    users: any[] = [];
    commentList: any[] = [];
    levelsList: any[] = [];
    achatUveList: any[] = [];
    venteUveList: any[] = [];
    approCashList: any[] = [];
    soutrali24HList: any[] = [];
    achatUveLevelId?: number;
    venteUveLevelId?: number;
    approCashLevelId?: number;
    soutrali24HLevelId?: number;
    message?: string
    hours: any[] = [];
    accountsList: any[] = [];
    CopyaccountsList: any[] = []
    operateurs: IOperateur[] = [];
    hoursTab: any[] = [];
    interv: number = 7
    day?: number
    SheduleForm: UntypedFormGroup;
    statistics: any[] = [];
    isCNI:boolean=false;
    isRCCM:boolean=false;
    isDFE:boolean=false;
    isBail:boolean=false;
    dayList: any[] = [
        { Id: 1, Title: "Lundi" },
        { Id: 2, Title: "Mardi" },
        { Id: 3, Title: "Mercredi" },
        { Id: 4, Title: "Jeudi" },
        { Id: 5, Title: "Vendredi" },
        { Id: 6, Title: "Samedi" },
        { Id: 7, Title: "Dimanche" },
    ]

    latitude?: number;
    longitude?: number;

    // Options de configuration pour la carte
    mapOptions: google.maps.MapOptions = {
        center: { lat: 0, lng: 0 },
        zoom: 0
    }
    markerPosition: google.maps.LatLngLiteral = {
        lat: 0,
        lng: 0,
    };

    constructor(
        private router: Router,
        private salepointService: SalepointService,
        private notificationService: NotificationService,
        private helper: HelperFunctions,
        private managerService: ManagerService,
        private commentService: CommentService,
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        private settingsService: SettingsService,
        private statService: StatService,
        private userService: UserService,
        private levelService: LevelService,
    ) {
        if (this.router.getCurrentNavigation() != null) {
            this.cacheSalepoint = this.router.getCurrentNavigation()?.extras?.state!['salepoint'] as ISalepoint;
        }
        this.helper.generateHours(this.hours)
        this.SheduleForm = this.createSheduleForm()
    }

    ngOnInit() {
        this.getSalpoint();
        this.getManagerList();
        this.getShedule();
        this.getAccountList();
        this.getSettings();
        this.loadStats(7);
        this.getComments();
        this.getUsers();
        this.getLevels()
    }


    getLevels() {
        this.levelService.getLevels().pipe(
            tap((response) => {
                this.levelsList = response.data
                this.achatUveList = this.levelsList.filter(lvl => lvl.type === "Achat d'UVE").reverse()
                this.venteUveList = this.levelsList.filter(lvl => lvl.type === "Vente d'UVE").reverse()
                this.approCashList = this.levelsList.filter(lvl => lvl.type === "Appro Cash").reverse()
                this.soutrali24HList = this.levelsList.filter(lvl => lvl.type === "SOUTRALI 24H").reverse()
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()
    }

    getUsers() {
        this.userService.getAll().pipe(
            tap((response) => {
                this.users = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()
    }

    //COMMENTS

    getComments() {
        this.commentService.getComments(this.cacheSalepoint.id as number).pipe(
            tap((response) => {
                this.commentList = response.data;
            }),
            catchError((errorMessage) => {
                this.notificationService.showError(errorMessage);
                throw errorMessage;
            })
        ).subscribe();
    }

    addComment() {
        if (this.message) {
            this.commentService.createComment(this.cacheSalepoint.id as number, this.message).pipe(
                tap((response) => {
                    this.notificationService.showSuccess(response.message);
                    this.getComments()
                    this.message = undefined
                }),
                catchError((errorMessage) => {
                    this.notificationService.showError(errorMessage);
                    throw errorMessage;
                })
            ).subscribe();
        }else {
            this.notificationService.showError("Entrez un commentaire s'il vous plait!");
        }
    }

    commentAuthor(id: number): string {
        const user: any = this.users.find(user => user.id === id)
        return user ? user.name : 'Unknown User';
    }


    //Configuration

    getSettings() {
        this.settingsService.settings$.subscribe((data) => {
            if (data) {
                this.operateurs = data.operateurs
            }
        });
    }


    //SCHEDULE

    getShedule() {
        this.salepointService.getShedules(this.cacheSalepoint.id as number).pipe(
            tap((response) => {
                this.hours = response.data;
                this.hoursTab = this.transformHourList(response.data);
                this.updateFormWithTransformedList();
            }),
            catchError((errorMessage) => {
                this.notificationService.showError(errorMessage);
                throw errorMessage;
            })
        ).subscribe();
    }

    private updateFormWithTransformedList(): void {
        if (this.hoursTab && this.hoursTab.length > 0) {
            this.SheduleForm = this.createSheduleForm()
        }
    }

    //ACCOUNT NUMBER

    getAccountList() {
        this.salepointService.getAccountNumberList(this.cacheSalepoint.id as number).pipe(
            tap((response) => {
                this.accountsList = response.data;
                for (const acc of this.accountsList) {
                    this.showMasterName(acc)
                }
            }),
            catchError((errorMessage) => {
                this.notificationService.showError(errorMessage);
                throw errorMessage;
            })
        ).subscribe();
    }


    deletePDV(account: any) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
            {
                width: '25%',
                data: {
                    id: account.id,
                    class: 'bg-danger text-white text-bold',
                    message: 'Suppression de ' + account.tel
                }
            })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.salepointService.deleteAccountNumber(this.cacheSalepoint.id as number, account.id).subscribe(
                    (response) => {
                        this.notificationService.showSuccess(response.message);
                        const index = this.CopyaccountsList.indexOf(account)
                        this.CopyaccountsList.splice(index, 1)
                    },
                    (errorMessage) => {
                        this.notificationService.showError(errorMessage);
                    }
                )
            } else {
                return;
            }
        })
    }

    showMasterName(acc: any) {
        if (acc.master_id) {
            this.salepointService.getMasterById(acc.master_id).subscribe((master) => {
                const masterName = {
                    id: acc.id,
                    operateur: acc.operateur,
                    master_name: master.data.name,
                    tel: acc.tel
                }
                if (!this.CopyaccountsList.find(account => account.id === masterName.id)) {
                    this.CopyaccountsList.push(masterName)
                }
            })
        } else {
            const masterName = {
                id: acc.id,
                operateur: acc.operateur,
                master_name: "Master",
                tel: acc.tel
            }
            if (!this.CopyaccountsList.find(account => account.id === masterName.id)) {
                this.CopyaccountsList.push(masterName)
            }
        }
    }

    libelleFromStatus(status: string) {
        return this.helper.getLibelleFromStatus(status)
    }

    getClassFromStatus(status: string) {
        return this.helper.getStatusClass(status);
    }

    updateVerified() {
        const data = {
            verified: !this.salepoint.verified,

        }

        this.salepointService.updatePDVFinal(data, this.salepoint.id as number).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
            }),
            catchError((errorMessage) => {
                this.notificationService.showError(errorMessage);
                throw errorMessage;
            })
        ).subscribe();
    }

    updateChecked() {
        const data = {
            verified: !this.salepoint.verified,
        }

        this.salepointService.updatePDVFinal(data, this.salepoint.id as number).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.slideToggle!.checked = true
                this.activate = true
            }),
            catchError((errorMessage) => {
                this.notificationService.showError(errorMessage);
                this.slideToggle!.checked = false
                this.activate = false
                throw errorMessage;
            })
        ).subscribe();
        this.changeDetectorRef.markForCheck();
    }

    getSalpoint() {
        this.salepointService.getForId(this.cacheSalepoint.id as number).pipe(
            tap((reponse) => {
                this.salepoint = reponse.data;
                this.agenceType = reponse.data.type
                this.achatUveLevelId = this.salepoint.purchase_level_id!;
                this.venteUveLevelId = this.salepoint.sell_level_id!;
                this.approCashLevelId = this.salepoint.appro_level_id!;
                this.soutrali24HLevelId = this.salepoint.soutrali_level_id!;

                this.isCNI= this.isFileChecked(this.salepoint.manager_front_photo)
                this.isRCCM= this.isFileChecked(this.salepoint.commercial_register)
                this.isDFE= this.isFileChecked(this.salepoint.tax_exist)
                this.isBail= this.isFileChecked(this.salepoint.lease_contract)


                this.mapOptions = {
                    center: { lat: 5.396198, lng: -3.971412 },
                    zoom: 12
                };

                this.markerPosition = {
                    lat: 5.396198,
                    lng: -3.971412
                };
            }),
            catchError((error) => { throw error })
        ).subscribe()
    }

    updatePDVLevels(levelId: number, level: string) {
        let data: any
        if (level === "achat") {
            data = { purchase_level_id: levelId }
        } else if (level === "vente") {
            data = { sell_level_id: levelId }
        }
        else if (level === "appro") {
            data = { appro_level_id: levelId }
        }
        else if (level === "soutra") {
            data = { soutrali_level_id: levelId }
        }

        this.salepointService.updatePDVFinal(data, this.cacheSalepoint.id as number).pipe(
            tap((response) => {
                console.log(response);
            }),
            catchError((errorMessage) => {
                this.notificationService.showError(errorMessage);
                throw errorMessage;
            })
        ).subscribe();
    }

    removeSeconds(time: string): string {
        if (!time) return time; // Si la chaîne est vide ou null, retourner la chaîne telle quelle
        const parts = time.split(':'); // Diviser la chaîne en parties (heures, minutes, secondes)
        if (parts.length !== 3) return time; // Si la chaîne n'est pas au format attendu, retourner la chaîne telle quelle
        return parts.slice(0, 2).join(':'); // Rejoindre les parties heures et minutes et les retourner
    }

    numTodays(num: number): string {
        const day = this.dayList.find(d => d.Id === num)
        return day.Title
    }

    isFileChecked(file: File | string | undefined): boolean {
        return typeof file === 'string';
    }

    // Les statistiques
    loadStats(val: number) {
        this.statService.getStatsList(this.cacheSalepoint.id as number, val).pipe(
            tap((response) => {
                this.statistics = response.data.salepoint
            }),
            catchError((errorMessage) => {
                this.notificationService.showError(errorMessage);
                throw errorMessage;
            })
        ).subscribe();
    }

    selectInterval(interval: number) {
        this.interv = interval
        this.loadStats(interval)
    }



    updateType(agenceType: string) {
        const data = {
            type: agenceType,
        }
        this.salepointService.updatePDVFinal(data, this.cacheSalepoint.id as number).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);

            }),
            catchError((errorMessage) => {
                this.notificationService.showError(errorMessage);
                throw errorMessage;
            })
        ).subscribe();
    }

    //MANAGERS

    getManagerList() {
        this.managerService.getList(this.cacheSalepoint.id as number).pipe(
            tap((response) => {
                const tab: any[] = response.data;
                this.managerList = tab.filter(man => man.accept === false)
                this.managerAcceptedList = tab.filter(man => man.accept !== false)
                const man = this.managerAcceptedList.find(manager => manager.user_id === this.salepoint.user_id)
                setTimeout(() => {
                    this.manager = this.users.find(user => user.id === man.user_id)
                    console.log(this.manager)
                }, 500)
            }),
            catchError((errorMessage) => {
                this.notificationService.showError(errorMessage);
                throw errorMessage;
            })
        ).subscribe();
    }

    rejectManager(manager: any) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
            {
                width: '25%',
                data: {
                    id: manager.id,
                    class: 'bg-danger text-white text-bold',
                    message: 'Suppression de ' + manager.user
                }
            })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.managerService.rejectManager(this.cacheSalepoint.id as number, manager.id).subscribe(
                    (data) => {
                        const index = this.managerList.indexOf(manager)
                        this.managerList.splice(index, 1)
                        this.notificationService.showSuccess(data.message);
                    },
                    (errorMessage: any) => {
                        this.notificationService.showError(errorMessage);
                    })
            } else {
                return;
            }
        })
    }

    acceptManager(manager: any) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
            {
                width: '25%',
                data: {
                    id: manager.id,
                    class: 'bg-success text-white text-bold',
                    message: 'Accepter la demande de ' + manager.user
                }
            })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.managerService.acceptManager(this.cacheSalepoint.id as number, manager.id).subscribe(
                    (data) => {
                        const index = this.managerList.indexOf(manager)
                        this.managerList.splice(index, 1)
                        this.managerAcceptedList.unshift(manager)
                        this.notificationService.showSuccess(data.message);
                    },
                    (errorMessage: any) => {
                        this.notificationService.showError(errorMessage);
                    })
            } else {
                return;
            }
        })
    }

    chooseManager() {
        const dialogRef = this.dialog.open(ChooseManagerDialogComponent,
            {
                width: '50%',
                height: '50%',
                data: {
                    id: this.cacheSalepoint.id,
                    managerList: this.managerAcceptedList
                }
            })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.salepointService.getForId(this.cacheSalepoint.id as number).pipe(
                    tap((reponse) => {
                        this.salepoint = reponse.data;
                        const man = this.managerAcceptedList.find(manager => manager.user_id === this.salepoint.user_id)
                        this.manager = this.users.find(user => user.id === man.user_id)
                        this.notificationService.showSuccess("Gérant choisi!");
                    }),
                    catchError((error) => { throw error })
                ).subscribe()
            } else {
                return;
            }
        })
    }

    onManagerDetail(manager: any) {
        const currentManager = this.users.find(user => user.id === manager.user_id)


        const dialogRef = this.dialog.open(ManagerDetailDialogComponent,
            {
                width: '50%',
                height: '50%',
                data: {
                    id: this.cacheSalepoint.id,
                    manager: currentManager
                }
            })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.managerService.rejectManager(this.cacheSalepoint.id as number, manager.id).subscribe(
                    (data) => {
                        const index = this.managerAcceptedList.indexOf(manager)
                        this.managerAcceptedList.splice(index, 1)
                        this.notificationService.showSuccess(data.message);
                    },
                    (errorMessage: any) => {
                        this.notificationService.showError(errorMessage);
                    })
            } else {
                return;
            }
        })
    }

    createSheduleForm(): UntypedFormGroup {
        let hourArray: FormGroup[] = [];

        if (this.hoursTab) {
            hourArray = this.hoursTab.map(h =>
                this.fb.group({
                    day: [h.day, [Validators.required]],
                    ouverture: [this.helper.formatTimeToString(h.ouverture), [Validators.required]],
                    fermeture: [this.helper.formatTimeToString(h.fermeture), [Validators.required]],
                    id: [h.id, [Validators.required]],

                })
            );
        }

        return this.fb.group({
            Hours: this.fb.array(hourArray),
        });
    }

    get Hours() {
        return this.SheduleForm.get('Hours') as FormArray;
    }


    transformHourList(hourListData: any[]): any[] {
        const transformedList: any[] = [];

        // Parcourez chaque élément de hourList
        hourListData.forEach((hourItem) => {
            const day = this.numTodays(hourItem.day_of_week);

            transformedList.push({
                day: day,
                ouverture: hourItem.opening_time,
                fermeture: hourItem.closing_time,
                id: hourItem.id
            });

        });
        return transformedList;
    }


    registerShedule() {
        const formdata = this.SheduleForm.getRawValue()
        for (const hour of formdata.Hours) {
            const data = {
                id: hour.id,
                day_of_week: this.dayToNum(hour.day),
                salepoint_id: this.cacheSalepoint.id,
                opening_time: this.helper.formatTimeToHourMinute(hour.ouverture),
                closing_time: this.helper.formatTimeToHourMinute(hour.fermeture),
            }
            this.salepointService.updateShedules(data).subscribe(
                () => {
                    // this.SheduleForm.reset()
                    this.day = undefined
                },
                (errorMessage) => {
                    this.notificationService.showError(errorMessage);
                })
        }

        this.notificationService.showSuccess("Modification effectuée");
        setTimeout(() => { this.editSched = false }, 500)

    }
    dayToNum(dy: string): number {
        const day = this.dayList.find(d => d.Title === dy)
        return day.Id
    }
    allowScheUpdate() {
        this.editSched = true
    }

    /**
     * ACCOUNT NUMBER
     * 
     */

    showLogo(name: string): string {
        const operateur = this.operateurs.find(op => op.name.toLowerCase() === name.toLowerCase())
        if (operateur) {
            return operateur.logo
        } else {
            return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwp3I-2Bte7Qs6PDDXYSonv_t8vSI9lzh3Wg&usqp=CAU"
        }
    }

    addAccount() {
        const dialogRef = this.dialog.open(AddAccountDialogComponent,
            {
                width: '40%',
                height: '70%',
                data: {
                    id: this.cacheSalepoint.id,
                }
            })
        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.getAccountList()
            } else {
                return;
            }
        })
    }

    addFile() {
        const dialogRef = this.dialog.open(AddDocDialogComponent,
            {
                width: '40%',
                height: '50%',
                data: {
                    id: this.cacheSalepoint.id,
                }
            })
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getSalpoint()
            } else {
                return;
            }
        })
    }
}
