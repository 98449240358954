import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/api/notification.service';
import { SubscriptionService } from 'src/app/api/subscription.service';

@Component({
    selector: 'app-abonnement-dialog',
    templateUrl: './abonnement-dialog.component.html',
    styleUrls: ['./abonnement-dialog.component.scss']
})
export class AbonnementDialogComponent  {
    subsList: any[] = [];
    pdv_name:string;
    salepoint_id: number;

    constructor(
        public matDialogRef: MatDialogRef<AbonnementDialogComponent>,
        private subService: SubscriptionService,
        private notificationService: NotificationService,
        
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.subsList=data.sub
        this.salepoint_id = data.agency.id;
        this.pdv_name=data.agency.name
    }

    


    changeSubState(sub:any) {
        this.subService.updateSubscriptions(this.salepoint_id, sub).subscribe((resp) => {
            console.log("data ",  resp);
        });
    }
}
