import { Injectable } from '@angular/core';
import { CanActivate, NavigationEnd, Router } from '@angular/router';
import { AppStorageService } from '../helpers/app-storage.service';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root',
})
export class GuestGuard implements CanActivate {
    activeRoute: string = '';

    constructor(
        private router: Router,
        private appStorageService: AppStorageService,
        private authService: AuthService,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.activeRoute = event.url;
            }
        });
    }

    canActivate(): boolean {
        const refresh_token = this.appStorageService.getRefreshToken()

        if (refresh_token) {
            this.authService.refreshToken(refresh_token).subscribe(
                () => {
                    this.router.navigate(['/accueil'])
                },
            )
            // return true;
        }
        return true;
    }
}