import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStorageService } from './helpers/app-storage.service';
import { BaseRepository } from './base/base-repository';
import { catchError, map, throwError } from 'rxjs';
import { IForfait } from '../Types/forfait';

@Injectable({
    providedIn: 'root',
})
export class ForfaitService extends BaseRepository {
    constructor(http: HttpClient, appStorageService: AppStorageService) {
        super('forfaits', http, appStorageService);
    }

    createForfait(forfait: IForfait) {

        return this.http.post<any>(this.url + this.controllerName, forfait).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    getFofaits(){
        return this.http.get<any>(this.url + this.controllerName, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    updateForfait(id:number, forfait: IForfait) {
        const url = `${this.url + this.controllerName}/${id}`

        return this.http.put<any>(url, forfait).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }


    deleteforfait( id: number) {
        const url = `${this.url + this.controllerName}/${id}`

        return this.http.delete<any>(url).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }
    
}
