import { Component, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { PDVModel } from 'src/app/Types/pdv-model';
import { SalepointService } from 'src/app/api/salepoint.service';
import { MatDialog } from '@angular/material/dialog';
import { AbonnementDialogComponent } from '../../dialogs/abonnement-dialog/abonnement-dialog.component';
import { NotificationService } from 'src/app/api/notification.service';
import { catchError, tap } from 'rxjs';
import { SubscriptionService } from 'src/app/api/subscription.service';

@Component({
    selector: 'app-abonnement',
    templateUrl: './abonnement.component.html',
    styleUrls: ['./abonnement.component.scss']
})
export class AbonnementComponent implements OnInit {
    agencies: PDVModel[]=[];
    name?:string;
    master?:string;
    tel?:number;
    params = new URLSearchParams();

    selectedAgencies!: PDVModel[];
    subsList: any[] = [];
  
    loading: boolean = true;


    constructor(private salepointService: SalepointService,
        private notificationService: NotificationService,
        private subService: SubscriptionService,
        public dialog: MatDialog,) { }

    ngOnInit() {
        this.loadData()
    }
    loadData(){
        this.salepointService.getPDVList().subscribe((res) => {
            this.agencies = res.data;
            this.loading = false;  

            // this.agencies.forEach((customer) => (customer.date = new Date(<Date>customer.date)));
        });
    }
    
    onGlobalFilter(table: Table, event: Event) {
        table.filterGlobal((event.target as HTMLInputElement).value,'contains');
    }

    onSearch(){
        console.log('recheche', this.name, this.master, this.tel)
    }

    edit(agence:any) {
        this.subService.getSubscriptions(agence.id).subscribe((resp) => {
            this.subsList = resp.data

            const dialogRef = this.dialog.open(AbonnementDialogComponent, 
                { width: '60%', height: '70%', data: {agency:agence, sub:this.subsList} });
    
            dialogRef.afterClosed().subscribe(result => {
                this.ngOnInit();
                console.log(result);
            });
        });
        
    }
    

    search() {
        let searchString = '';
        if (this.name) {
            this.params.set('name', this.name);
        }
        
        searchString = '?' + this.params.toString();
        console.log(searchString);
        
        this.find(searchString)
    }



    find(searchString: string){
        this.salepointService.search(searchString).pipe(
            tap((reponse) => {

                this.agencies =  reponse.data
                this.notificationService.showSuccess("Résultat : " + reponse.data.length)
            }),
            catchError((error) => { throw error })
        ).subscribe()
    }

}
