import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SalepointService } from 'src/app/api/salepoint.service';


@Component({
    selector: 'app-choose-manager-dialog',
    templateUrl: './choose-manager-dialog.component.html',
    styleUrls: ['./choose-manager-dialog.component.scss']
})
export class ChooseManagerDialogComponent {
    managerList: any[]=[];
    salepoint_id:any
    constructor(
        public matDialogRef: MatDialogRef<ChooseManagerDialogComponent>,
        private salepointService : SalepointService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.managerList = data.managerList;
        this.salepoint_id = data.id
    }

    
    choose(id:number){
        const data = {
            user_id: id,
        }

        this.salepointService.updatePDVFinal(data, this.salepoint_id as number).subscribe()
    }
    
    
}
