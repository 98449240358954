    <div class="row">
        <div class="col-md-6 offset-md-3 col-sm-12 mt-4">
            <div class="title">
                <h5> Configuration Agence</h5>
            </div>
            <div class="fond">
                <mat-radio-group [(ngModel)]="agenceType">
                    <div>
                        <mat-radio-button color="primary"
                         (click)="register('Box ou Cabine')"
                        value="Box ou Cabine">
                        Box ou Cabine
                        </mat-radio-button>
                    </div>
                    <div>
                        <mat-radio-button 
                        (click)="register('Comptoir dans un magasin sans guichet ni grille')"
                        value="Comptoir dans un magasin sans guichet ni grille" color="primary">
                        Comptoir dans un magasin sans guichet ni grille
                    </mat-radio-button>
                    </div>
                    <div>
                        <mat-radio-button 
                        (click)="register('Agence avec guichet et grille')"
                        value="Agence avec guichet et grille" color="primary">
                        Agence avec guichet et grille
                    </mat-radio-button>
                    </div>
                    <div>
                        <mat-radio-button 
                        (click)="register('Agence sécurisée avec caméra')"
                        value="Agence sécurisée avec caméra" color="primary">
                        Agence sécurisée avec caméra
                    </mat-radio-button>
                    </div>
                    <div>
                        <mat-radio-button 
                        (click)="register('Agence sécurisée avec caméra et virgile')"
                        value="Agence sécurisée avec caméra et virgile" color="primary">
                        Agence sécurisée avec caméra et virgile
                    </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="mt-5 d-flex justify-content-center">
            <button class="btn w-50" (click)="prev()" ><i class="bi bi-caret-left-fill"></i>Retour</button>
        </div>
    </div>