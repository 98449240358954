import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStorageService } from './helpers/app-storage.service';
import { BaseRepository } from './base/base-repository';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionTelService extends BaseRepository {
    constructor(http: HttpClient, appStorageService: AppStorageService) {
        super('subscription_tels', http, appStorageService);
    }

    getSubscriptionTels() {
        return this.http.get<any>(this.url+this.controllerName, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    createSubsTels(data: any) {
       
        return this.http.post<any>(this.url + this.controllerName, data).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }

    updateSubTels(id: number, data: any) {
       
        return this.http.put<any>(this.url + this.controllerName+ "/" + id, data).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }

    deletesubstel( id: number) {
    
        return this.http.delete<any>(this.url + this.controllerName+ "/" + id, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

}
