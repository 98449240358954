import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs';

import { NotificationService } from 'src/app/api/notification.service';
import { SettingsService } from 'src/app/api/setting.service';
import { IOperateur } from 'src/app/Types/operateurs';
import { SubscriptionTelService } from 'src/app/api/subscription-tels.service';
import { NumeroCustomerService } from 'src/app/api/numero.service';
import { ICustomTel } from 'src/app/Types/custom-tels';

@Component({
    selector: 'app-add-customTel-dialog',
    templateUrl: './add-customTel-dialog.component.html',
    styleUrls: ['./add-customTel-dialog.component.scss']
})
export class AddCustomTelDialogComponent implements OnInit {
    customtelForm: FormGroup = this._formBuilder.group({
        type: ['', [Validators.required]],
        tel: ['', [Validators.required]],
        icon: [''],
        //updated_at: [''],
     
    });
    roles: any;
    document_type: any[] = [];
    lockDuringSending = false;
    typeAndIcon:any[]=[{type:"Whatsapp", icon:"ri-whatsapp-line"},{type:"Appel", icon:"ri-phone-line"}]
    initialCustomTel: ICustomTel;
    sendTitle: string = '';
    constructor(
        public matDialogRef: MatDialogRef<AddCustomTelDialogComponent>,
        private _formBuilder: FormBuilder,
        private customService:NumeroCustomerService,
        private settingsService: SettingsService,
        private notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) public customtel: ICustomTel,
    ) {
        this.initialCustomTel = customtel;
    }

    ngOnInit(): void {
        

        if (this.initialCustomTel) {
            this.initForfaitForm(this.initialCustomTel);
            this.sendTitle = 'Modiffier';


        } else {
            this.sendTitle = 'Enregister';
            

        }
    }


    

    /**
     * Formulaire de modification
     */
    initForfaitForm(customTel: ICustomTel): void {
        this.customtelForm.get('type')?.setValue(customTel.type);
        this.customtelForm.get('tel')?.setValue(customTel.tel);
        //this.customtelForm.get('created_at')?.setValue(customTel.created_at);
        //this.customtelForm.get('updated_at')?.setValue(customTel.updated_at);

    }



    post(formData: any) {        
       
        this.customService.createCustomerTels(formData).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.matDialogRef.close(true);
            }),
            catchError((fail) => {
                this.notificationService.showError(fail.error.message);
                this.lockDuringSending = false;
                throw fail;
            })
        ).subscribe()
    }

    update(formData: any) {
     
        this.customService.updateCustomerTels(this.initialCustomTel.id as number, formData).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.matDialogRef.close(true);
            }),
            catchError((fail) => {
                this.notificationService.showError(fail.error.message);
                this.lockDuringSending = false;
                throw fail;
            })
        ).subscribe()
    }

    send() {
        this.lockDuringSending = true;

        const formData = { ...this.customtelForm.value };
  
        const choosedItem = this.typeAndIcon.find(item=>item.type === formData.type)
        formData.icon = choosedItem.icon
        

       if (this.initialCustomTel) {
            
            return this.update(formData);
        }
      
        return this.post(formData); 

    }
}
