import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/Modules/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SalepointService } from 'src/app/api/salepoint.service';
import { HelperFunctions } from 'src/app/helpers/helpers';
import { NotificationService } from 'src/app/api/notification.service';

@Component({
    selector: 'app-fif-step',
    templateUrl: './shedules.component.html',
    styleUrls: ['./shedules.component.scss']
})
export class SheduleComponent implements OnInit {
    pdvId?: number
    hours: string[] = []
    day?: number
    SheduleForm: UntypedFormGroup;
    hoursTab: any[] = []
    dayList: any[] = [
        { Id: 1, Title: "Lundi" },
        { Id: 2, Title: "Mardi" },
        { Id: 3, Title: "Mercredi" },
        { Id: 4, Title: "Jeudi" },
        { Id: 5, Title: "Vendredi" },
        { Id: 6, Title: "Samedi" },
        { Id: 7, Title: "Dimanche" },
    ]
    constructor(
        private pdvService: SalepointService,
        private helper: HelperFunctions,
        private notificationService: NotificationService,
        private fb: UntypedFormBuilder,
        public dialog: MatDialog,

    ) {
        // const salepoint_id = Number(localStorage.getItem('pdvId'))

        this.helper.generateHours(this.hours)

        this.pdvService.commonMessage.subscribe(m => {
            this.pdvId = m.pdvId
        })


        this.SheduleForm = this.createSheduleForm()
    }


    ngOnInit(): void {
        this.pdvService.getShedules(this.pdvId!).subscribe(
            (shed) => {
                this.hoursTab = this.transformHourList(shed.data);
                this.updateFormWithTransformedList();
            }
        )
    }

    private updateFormWithTransformedList(): void {

        if (this.hoursTab && this.hoursTab.length > 0) {

            this.SheduleForm = this.createSheduleForm()
        }
    }


    reverseItems(items: any[]) {
        return items.reverse();
    }

    loadShed() {
        this.pdvService.getShedules(this.pdvId!).subscribe(
            (shed) => {
                this.hoursTab = this.transformHourList(shed.data);

            }
        )
    }

    next() {
        this.pdvService.sendData({ step: 6, pdvId: this.pdvId })
    }
    prev() {
        this.pdvService.sendData({ step: 4, pdvId: this.pdvId })
    }

    selectDay(dayIndex: number) {
        const days = document.querySelectorAll('.day');
        days.forEach(day => {
            day.classList.remove('selected');
        });

        const selectedDay = days[dayIndex];
        selectedDay.classList.add('selected');
        this.day = dayIndex
    }

    numTodays(num: number): string {
        const day = this.dayList.find(d => d.Id === num)
        return day.Title
    }

    dayToNum(dy: string): number {
        const day = this.dayList.find(d => d.Title === dy)
        return day.Id
    }

    createSheduleForm(): UntypedFormGroup {
        let hourArray: FormGroup[] = [];

        if (this.hoursTab) {
            hourArray = this.hoursTab.map(h =>
                this.fb.group({
                    day: [h.day, [Validators.required]],
                    ouverture: [this.helper.formatTimeToString(h.ouverture), [Validators.required]],
                    fermeture: [this.helper.formatTimeToString(h.fermeture), [Validators.required]],
                    id: [h.id, [Validators.required]],

                })
            );
        }

        return this.fb.group({
            Hours: this.fb.array(hourArray),
        });

    }

    get Hours() {
        return this.SheduleForm.get('Hours') as FormArray;
    }

    deleteShedule(index: number) {
        const selectedHour = this.hoursTab[index];
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,  {
            width: '25%',
            data: {
                id:selectedHour.id,
                class:'bg-danger text-white text-bold',
                message: 'Suppression de ' + selectedHour.day 
            }
        });
        
        dialogRef.afterClosed().subscribe(result => {
            
            if(result){
                this.Hours.removeAt(index); 
                this.pdvService.deleteShedule(this.pdvId!,selectedHour.id).subscribe(
                    (data) => {
                        this.loadShed()
                        
                        this.notificationService.showSuccess(data.message);

                    },
                    (errorMessage:any) => {
                        this.notificationService.showError(errorMessage);
                    }) 
            }else{
                return;
            }
            

        })
    }

    transformHourList(hourListData: any[]): any[] {
        const transformedList: any[] = [];

        // Parcourez chaque élément de hourList
        hourListData.forEach((hourItem) => {
            const day = this.numTodays(hourItem.day_of_week);

            transformedList.push({
                day: day,
                ouverture: hourItem.opening_time,
                fermeture: hourItem.closing_time,
                id: hourItem.id
            });

        });

        return transformedList;
    }

    trierParJoursSemaine(elements: any[]): any[] {
        const joursSemaine = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

        // Copie du tableau et tri selon l'ordre des jours de la semaine
        const elementsTries = [...elements].sort((a, b) => this.comparerJoursSemaine(a, b, joursSemaine));

        return elementsTries;
    }

    comparerJoursSemaine(a: any, b: any, joursSemaine: string[]): number {
        return joursSemaine.indexOf(a.day.toLowerCase()) - joursSemaine.indexOf(b.day.toLowerCase());
    }
    removeSeconds(time: string): string {
        if (!time) return time; // Si la chaîne est vide ou null, retourner la chaîne telle quelle
        const parts = time.split(':'); // Diviser la chaîne en parties (heures, minutes, secondes)
        if (parts.length !== 3) return time; // Si la chaîne n'est pas au format attendu, retourner la chaîne telle quelle
        return parts.slice(0, 2).join(':'); // Rejoindre les parties heures et minutes et les retourner
    }

    registerShedule() {
        const formdata = this.SheduleForm.getRawValue()
        for (const hour of formdata.Hours) {

            const data = {
                id: hour.id,
                day_of_week: this.dayToNum(hour.day),
                salepoint_id: this.pdvId,
                opening_time: this.helper.formatTimeToHourMinute(hour.ouverture),
                closing_time: this.helper.formatTimeToHourMinute(hour.fermeture),
            }
            this.pdvService.updateShedules(data).subscribe(
                () => {

                    // this.SheduleForm.reset()
                    this.day = undefined
                },
                (errorMessage) => {
                    this.notificationService.showError(errorMessage);
                })
        }

        this.loadShed()
        this.notificationService.showSuccess("Modification effectuée");
    }
}
