import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCommonModule, MatOptionModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RadioButtonModule } from 'primeng/radiobutton';
import { LayoutModule } from '../layout/layout.module';
import { AddAccountDialogComponent } from './add-account-dialog/add-account-dialog.component';
import { AddDocDialogComponent } from './add-doc-dialog/add-doc-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ChooseManagerDialogComponent } from './choose-manager-dialog/choose-manager-dialog.component';
import { ManagerDetailDialogComponent } from './manager-detail-dialog/manager-detail-dialog.component';
import { AddForfaitDialogComponent } from './add-forfait-dialog/add-forfait-dialog.component';
import { AbonnementDialogComponent } from './abonnement-dialog/abonnement-dialog.component';
import { CheckboxModule } from 'primeng/checkbox';
import { AddSubsTelDialogComponent } from './add-subsTel-dialog/add-subsTel-dialog.component';
import { AddCustomTelDialogComponent } from './add-customTel-dialog/add-customTel-dialog.component';



@NgModule({
    declarations: [
        AddUserDialogComponent,
        ConfirmationDialogComponent,
        AddAccountDialogComponent,
        AddDocDialogComponent,
        ChooseManagerDialogComponent,
        ManagerDetailDialogComponent,
        AddForfaitDialogComponent,
        AddSubsTelDialogComponent,
        AbonnementDialogComponent,
        AddCustomTelDialogComponent
    ],
    imports: [
        MatButtonModule,
        MatIconModule,
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDialogModule,
        LayoutModule,
        MatCommonModule,
        MatMenuModule,
        MatStepperModule,
        MatListModule,
        MatOptionModule,
        MatAutocompleteModule,
        NgxMaterialTimepickerModule,
        MatCheckboxModule,
        RadioButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        DatePipe,
        MatDialogModule,
        CheckboxModule,
        
    ],
    providers: [DatePipe],
})
export class DialogsModule { }
