import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPdvRoutingModule } from './search-pdv-routing.module';
import { SearchPdvComponent } from './search-pdv.component';
import { LayoutModule } from '../layout/layout.module';
import { MatTabsModule } from '@angular/material/tabs';
import { SearchFormComponent } from './search-form/search-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSliderModule} from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatSelectModule} from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SearchResultComponent } from './search-result/search-result.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { CustomPaginatorIntl } from 'src/app/helpers/custom-paginator-intl';
import { SearchDetailComponent } from './search-detail/search-detail.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {MatRadioModule} from '@angular/material/radio';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [ 
        SearchPdvComponent,
        SearchFormComponent,
        SearchResultComponent,
        SearchDetailComponent,
    ],
    imports: [
        MatSlideToggleModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatChipsModule,
        FormsModule,
        ReactiveFormsModule,
        MatSliderModule,
        MatTooltipModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatFormFieldModule,
        MatTabsModule,
        LayoutModule,
        CommonModule,
        MatCheckboxModule,
        NgxMaterialTimepickerModule,
        SearchPdvRoutingModule,
        MatRadioModule,
        GoogleMapsModule,
        MatProgressSpinnerModule,
       
    ],
    providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }]
})
export class SearchPdvModule { }
