<app-header></app-header>
<div class="content">
	<div class="container">
		<div class="row justify-content-center">
			<mat-tab-group (selectedTabChange)="onTabChange($event)">
				<mat-tab label="MANUEL">
					<app-search-form [zone]="false"></app-search-form>
				</mat-tab>
				<mat-tab label="ZONE">
					<app-search-form [zone]="true"></app-search-form>
				</mat-tab>
			</mat-tab-group>
		</div>
		<div class="row justify-content-center">
			<button mat-fab color="primary" (click)="search()">
				<mat-icon>search</mat-icon>
			</button>
		</div>

		
		<ng-container *ngIf="foundSalepoints?.length > 0">
			<div class="row justify-content-center">
				<mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length"
					[pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="true" [hidePageSize]="true"
					[pageIndex]="pageIndex" aria-label="Select page">
				</mat-paginator>
			</div>
		</ng-container>
	</div>
</div>
<div class="spinner-container" *ngIf="foundSalepoints?.length===0">

	<mat-progress-spinner *ngIf="foundSalepoints?.length===0" [diameter]="50"
		mode="indeterminate"></mat-progress-spinner>

</div>

<div class="container-fluid mb-5" *ngIf="foundSalepoints?.length > 0">
	<hr class="hr">
	<app-search-result [salepoints]="foundSalepoints"></app-search-result>
</div>