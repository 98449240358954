import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { NotificationService } from 'src/app/api/notification.service';
import { MenuService } from 'src/app/api/menu.service';
import { catchError, tap } from 'rxjs';
import { IMenu } from 'src/app/Types/menu';

@Component({
    selector: 'main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
})
export class HomeComponent implements OnInit {

    session: any;
    menuList: IMenu[] = [];
    constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private menuService: MenuService,
    ) {
        let user:any="";
        user = localStorage.getItem('currentUser');
        if (user) {
            this.session = JSON.parse(user);
        }
    }

    ngOnInit(): void {
        this.getMenuList();
    }

    public getMenuList() {
        this.menuService.getAll().pipe(
            tap((response) => {
                this.menuList = response.data
            }),
            catchError((fail) => {
                this.notificationService.showError(fail.error.message);
                throw fail;
            })
        ).subscribe()
    }

    logout() {
        this.authService.logout().subscribe(
            (res) => {
                this.notificationService.showSuccess(res.message);
                this.router.navigateByUrl('/connexion');
            },
            (errorMesssage) => {
                this.notificationService.showError(errorMesssage);
            },
        );
    }
}
