import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AppStorageService {
    getToken(): string | undefined {
        const token = localStorage.getItem('AUTH-TOKEN');
        return token && token.trim() !== '' ? token : undefined;
    }

    getRefreshToken(): string | undefined {
        const refresh_token = localStorage.getItem('REFRESH-TOKEN');
        return refresh_token && refresh_token.trim() !== '' ? refresh_token : undefined;
    }

    setToken(value: string): boolean {
        if (!value || value === undefined || value.trim() === '') {
            return false;
        }

        localStorage.removeItem('AUTH-TOKEN');
        localStorage.setItem('AUTH-TOKEN', value);
        console.log('réécriture du token dans storage: ', value);
        return true;
    }

    setRefreshToken(value: string): boolean {
        if (!value || value === undefined || value.trim() === '') {
            return false;
        }

        localStorage.removeItem('REFRESH-TOKEN');
        localStorage.setItem('REFRESH-TOKEN', value);
        console.log('réécriture du refresh dans storage: ', value);
        return true;
    }

    removeRefreshToken(): void {
        localStorage.removeItem('REFRESH-TOKEN');
    }

    existsToken(): boolean {
        const token = localStorage.getItem('AUTH-TOKEN');

        return token != null && token !== undefined && token.trim() !== '';
    }

    removeToken(): void {
        localStorage.removeItem('AUTH-TOKEN');
    }

    setLocalStoragePageCurrent(page: string): void {
        localStorage.setItem('PAGE_CURRENT', page);
    }

    getHttpOptions(options: any): any {
        if (options) {
            return options;
        }

        const headersConfig = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        return {
            headers: new HttpHeaders(headersConfig),
        };
    }
}
