import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../api/auth.service';
import { User } from '../../Types/user.model';
import { NotificationService } from 'src/app/api/notification.service';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
    constructor(
    private builder: UntypedFormBuilder,
    private route: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    ) {}

    registerForm = this.builder.group({
        name: ['', Validators.required],
        last_name: ['', Validators.required],
        tel: ['', Validators.required],
        type_identity: ['', Validators.required],
        num_identity: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
    });

    register() {
        const formdata = this.registerForm.getRawValue();

        if (formdata.confirmPassword === formdata.password) {
            const newUser: User = {
                name: formdata.name,
                last_name: formdata.last_name,
                tel: formdata.tel,
                num_identity: formdata.num_identity,
                type_identity: formdata.type_identity,
                password: formdata.password,
            };

            this.authService.addNewUser(newUser).subscribe(
                (data) => {
                    this.notificationService.showSuccess(data.message);
                    this.route.navigateByUrl('/connexion');
                },
                (errorMessage) => {
                    return this.notificationService.showError(errorMessage);
                },
            );
        } else {
            this.notificationService.showError('Mot de passe non identique');
        }
    }
}
