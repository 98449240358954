import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/api/notification.service';
import { AuthService } from '../../../api/auth.service';
import { Location } from '@angular/common';
import { catchError, tap } from 'rxjs/operators';
import { LoaderService } from 'src/app/api/base/loader.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    session: any;
    isLoading: boolean = true;
    isScrolled: boolean = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        private notificationService: NotificationService,
        private location:Location,
        private loaderService: LoaderService,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        let user:any="";
        user = localStorage.getItem('currentUser');
        if (user) {
            this.session = JSON.parse(user);
        }
    }

    ngOnInit(): void {
        this.loaderService.loading$.subscribe((isLoading) => {
            this.isLoading = isLoading;
        });
    }

    public goToAbonnement(){
        console.log("bonjour");
        this.router.navigate(["/authorizations/abonnement"]);
    }


    @HostListener('window:scroll')
    onWindowScroll() {
        const top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.isScrolled = top > 0;
        //     if (this.isScrolled) {
        //         this.renderer.addClass(this.elementRef.nativeElement, 'scrolled');
        //     } else {
        //         this.renderer.removeClass(this.elementRef.nativeElement, 'scrolled');
        //     }

        if (this.isScrolled) {
            this.renderer.addClass(this.elementRef.nativeElement.querySelector('nav'), 'scrolled');
        } else {
            this.renderer.removeClass(this.elementRef.nativeElement.querySelector('nav'), 'scrolled');
        }
    }


    goBack(){
        this.location.back()
    }

    logout() {
        this.authService.logout().pipe(
            tap((res) => {
                this.notificationService.showSuccess(res.message);
                this.router.navigateByUrl('/connexion');
            }),
            catchError((errorMesssage) => {
                this.notificationService.showError(errorMesssage);
                throw errorMesssage;
            })
        ).subscribe();
    }
}
