<h3 mat-dialog-title class="text-bold text-center">{{sendTitle}} forfait</h3>

<mat-dialog-content class="custom-dialog-width">

	<form [formGroup]="forfaitForm">
		<div class="form-container">
			<div class="form-row">
			  <div class="form-field col-6 col-md-12">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
				  <mat-label>Titre</mat-label>
				  <input formControlName="title" matInput>
				</mat-form-field>
			  </div>
		  
			  <div class="form-field col-6 col-md-12">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
				  <mat-label>Montant</mat-label>
				  <input formControlName="amount" type="number" matInput>
				</mat-form-field>
			  </div>
			</div>
		  
			<div class="form-row">
			  <div class="form-field col-6 col-md-12">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
				  <mat-label>Durée (en jour)</mat-label>
				  <input formControlName="duree" type="number" matInput>
				</mat-form-field>
			  </div>
		  
			  <div class="form-field col-6 col-md-12">
				<mat-form-field class="fuse-mat-no-subscript w-100" appearance="outline">
				  <mat-label>Unité de durée</mat-label>
				  <input formControlName="duree_label" matInput>
				</mat-form-field>
			  </div>
			</div>
		  
			<div class="form-row full-width">
			  <mat-form-field class="w-100 col-12" appearance="outline">
				<mat-label>Description</mat-label>
				<input formControlName="description" matInput>
			  </mat-form-field>
			</div>
		  </div>
		  
	</form>
<mat-dialog-content>

<mat-dialog-actions class="justify-content-evenly">
	<button class="mb-2" mat-raised-button (click)="matDialogRef.close(false)">
		Annuler
	</button>
	
	<button mat-raised-button color="primary" type="submit" [disabled]="forfaitForm.invalid || lockDuringSending"
		(click)="send()">
		{{sendTitle}}
	</button>
</mat-dialog-actions>