import { NotificationService } from '../../../../api/notification.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs';
import { SalepointService } from 'src/app/api/salepoint.service';

@Component({
    selector: 'app-sixth-step',
    templateUrl: './required-doc.component.html',
    styleUrls: ['./required-doc.component.scss']
})
export class RequiredDocComponent {

    registerFile?: File
    taxeFile?: File
    leaseFile?: File
    cniFront?: File
    cniBack?: File
    pdvId?: number
    put: string = "put"


    constructor(
        private pdvService: SalepointService,
        private notificationService: NotificationService,
        private route: Router
    ) {
        this.pdvService.commonMessage.subscribe(m => {
            this.pdvId = m.pdvId
        })
    }


    prev() {
        this.pdvService.sendData({ step: 5, pdvId: this.pdvId })
    }

    openFileExplorer(content: string) {
        document.getElementById(content)!.click();
    }

    onFileSelected(event: Event, placeholder: string, lab: string) {
        let file
        const inputElement = event.target as HTMLInputElement;
        const fileNameSpan = document.getElementById(placeholder);
        const label = document.getElementById(lab);
        if (inputElement.files && inputElement.files.length > 0) {
            const fileName = inputElement.files[0].name;
            file = inputElement.files[0]
            this.addfileName(fileNameSpan as HTMLElement, label as HTMLElement, fileName)
        }
        return file
    }

    addfileName(fileNameSpan: HTMLElement | null, label: HTMLElement | null, fileName: string) {

        if (fileNameSpan && label) {
            fileNameSpan.textContent = fileName;
            label.textContent = ""
        }
        else {
            if (fileNameSpan) {
                fileNameSpan.textContent = '';
            }
        }
    }

    onRegisterFile(event: Event, placeholder: string, lab: string) {

        this.registerFile = this.onFileSelected(event, placeholder, lab)
    }

    onTaxeFile(event: Event, placeholder: string, lab: string) {

        this.taxeFile = this.onFileSelected(event, placeholder, lab)
    }

    onLeaseFile(event: Event, placeholder: string, lab: string) {

        this.leaseFile = this.onFileSelected(event, placeholder, lab)
    }
    oncniFrontFile(event: Event, placeholder: string, lab: string) {

        this.cniFront = this.onFileSelected(event, placeholder, lab)
    }
    oncniBacktFile(event: Event, placeholder: string, lab: string) {

        this.cniBack = this.onFileSelected(event, placeholder, lab)
    }

    next() {
        this.pdvService.sendData({ step: 1, pdvId: 0 })
    }

    CreateSalePoint() {
        // const pdvId=localStorage.getItem('pdvId')
        // const salepoint_id=this.pdvId?this.pdvId:""
        const data = new FormData();

        if (this.registerFile && this.taxeFile && this.leaseFile) {
            data.append('commercial_register', this.registerFile);
            data.append('tax_exist', this.taxeFile);
            data.append('lease_contract', this.leaseFile);
        }

        data.append('manager_front_photo', this.cniFront!);
        data.append('manager_back_photo', this.cniBack!);


        if (!this.cniBack || !this.cniFront) {
            this.notificationService.showError("Veuillez ajouter les documents du gérant");
        }
        else {
            this.pdvService.updatePDVFinal(data, this.pdvId as number).pipe(
                tap((response) => {
                    this.notificationService.showSuccess(response.message);
                    this.next()
                    this.route.navigateByUrl('/pdv-form/success-page')

                }),
                catchError((errorMessage) => {
                    this.notificationService.showError(errorMessage);
                    throw errorMessage;
                })
            ).subscribe();
        }




    }

}
