import { Component } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';

@Component({
    selector: 'app-zone',
    templateUrl: './zone.component.html',
    styleUrls: ['./zone.component.scss'],
  
})
export class ZoneComponent {
    session: any;
    constructor(private authService: AuthService) {}
}
