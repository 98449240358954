import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { communicationRoutingModule } from './communication-routing.module';
import { CommunicationComponent } from './communication.component';
import { LayoutModule } from '../layout/layout.module';
import { PdvFormModule } from '../dashboard/pdv-form/pdv-form.module';
import { AfficheComponent } from './affiche/affiche.component';
import { BanniereComponent } from './banniere/banniere.component';
import { OptionsComponent } from './options/options.component';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        CommunicationComponent,
        AfficheComponent,
        OptionsComponent,
        BanniereComponent
    ],
    imports: [
        CommonModule,
        PdvFormModule,
        LayoutModule,
        communicationRoutingModule,
        FormsModule
    ]
})
export class CommunicationModule { }
