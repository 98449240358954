import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { AccountNumber } from '../Types/pdv-model';
import { BaseRepository } from './base/base-repository';
import { AppStorageService } from './helpers/app-storage.service';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class SalepointService extends BaseRepository {
    private commonSubject = new BehaviorSubject({ step: 1, pdvId: 0 });
    public commonMessage = this.commonSubject.asObservable();
    constructor(
        http: HttpClient,
        appStorageService: AppStorageService,
        private notificationService: NotificationService
    ) {
        super('salepoints', http, appStorageService);
    }

    sendData(data: any) {
        this.commonSubject.next(data);
    }

    getPDVList() {
        return this.http.get<any>(this.url + "salepoints", {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    getMasterList(op: string) {

        return this.http.get<any>(this.url + this.controllerName + "/masters", { params: { operateur: op } }).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    createPDV(pdv: any) {

        return this.http.post<any>(this.url + this.controllerName, pdv).pipe(
            map((res) => {
                // localStorage.setItem('pdvId', res.data.id)
                console.log("response: ", res);
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }

    getSalepointById(pdvId: number) {

        return this.http.get<any>(this.url + this.controllerName + '/' + pdvId, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }

    updatePDVFinal(pdv: any, salepoint_id: number) {
        console.log("pdv ", pdv);
        
        
        let data
        if (pdv instanceof FormData) {
            pdv.append('_method', 'put')
            data = pdv
        } else {
            data = {
                ...pdv,
                _method: 'put'
            };
        }

        return this.http.post<any>(this.url + this.controllerName + '/' + salepoint_id, data).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }

                return throwError(errorMessage)
            })
        )
    }

    getMasterById(masterId: number) {

        return this.http.get<any>(this.url + this.controllerName + '/' + masterId, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }

    associatedSalepoint(id: number): Observable<string> {
        return this.getMasterById(id).pipe(
            map(salepoint => salepoint.data.name)
        );
    }

    createAccountNumber(acc: AccountNumber) {

        return this.http.post<any>(`${this.url + this.controllerName + '/' + acc.salepoint_id + '/account_numbers'}`, acc).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }

    deleteAccountNumber(salepoint_id: number, id: number) {
        const url = `${this.url + this.controllerName}/${salepoint_id}/account_numbers/`
        return this.http.delete<any>(url + id).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    getAccountNumberList(sId: number) {
        const url = `${this.url + this.controllerName}/${sId}/account_numbers`
        return this.http.get<any>(url, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }


    updateShedules(shed: any) {
        const url = `${this.url + this.controllerName}/${shed.salepoint_id}/shedules/${shed.id}`

        return this.http.put<any>(url, shed).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }


    getShedules(salepoint_id: number) {
        const url = `${this.url + this.controllerName}/${salepoint_id}/shedules`

        return this.http.get<any>(url).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }

    deleteShedule(salepoint_id: number, id: number) {
        const url = `${this.url + this.controllerName}/${salepoint_id}/shedules/${id}`

        return this.http.delete<any>(url).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                // console.log("erreur: ", errorMessage);

                return throwError(errorMessage)
            })
        )
    }

    public search(search: string, httpOptions?: any): Observable<any> {
        return this.http.get<any>(
            this.url + this.controllerName + search,
            this.appStorageService.getHttpOptions(httpOptions),
        );
    }
}
