import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { catchError, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/api/notification.service';
import { IRoles } from 'src/app/Types/roles';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddSubsTelDialogComponent } from '../../dialogs/add-subsTel-dialog/add-subsTel-dialog.component';
import { NumeroCustomerService } from 'src/app/api/numero.service';
import { ICustomTel } from 'src/app/Types/custom-tels';
import { AddCustomTelDialogComponent } from '../../dialogs/add-customTel-dialog/add-customTel-dialog.component';
@Component({
    selector: 'number-service',
    templateUrl: './number-service.component.html',
    styleUrls: ['./number-service.component.scss']
})
export class NumberServiceClientComponent implements OnInit {

    dataSource: ICustomTel[] = [];
    customerstels: ICustomTel[] = [];
    title: string = '';
    roles: IRoles[] = [];
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

    constructor(
        private customerService: NumeroCustomerService,
        public dialog: MatDialog,
        public notificationService: NotificationService,
    ) { }

    ngOnInit(): void {
        this.customerService.getCustomerTels().pipe(
            tap((response) => {
                this.title = "Liste des numeros du service client";
                this.dataSource = response.data
                this.customerstels = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()


    }



    addNewNum() {
        const dialogRef = this.dialog.open(AddCustomTelDialogComponent, { width: '50%',
        height: '55%'
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.ngOnInit();
        });
    }


    delete(customTel: ICustomTel) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
            {
                width: '25%',
                data: {
                    id: customTel.id,
                    class: 'bg-danger text-white text-bold',
                    message: 'Suppression du numéro ' + customTel.tel
                }
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.customerService.deleteCustomertel(customTel.id!).pipe(
                    tap((response) => {
                        this.notificationService.showSuccess(response.message);
                        this.ngOnInit();
                    }),
                    catchError((fail) => {
                        this.notificationService.showError(fail.error.message);
                        throw fail;
                    })
                ).subscribe()

            } else {
                return;
            }
        });

    }

    edit(customTel: ICustomTel) {
        console.log(customTel);
        const dialogRef = this.dialog.open(
            AddCustomTelDialogComponent,
            { width: '50%',
            height: '55%'
            ,data: customTel });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.ngOnInit();
        });
    }

   
}