import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationComponent } from './authorization.component';
import { UsersComponent } from './users/users.component';
import { OptionsComponent } from './options/options.component';
import { AbonnementComponent } from './abonnement/abonnement.component';
import { ForfaitComponent } from './forfaits/forfait.component';
import { NumberListComponent } from './number-list/number-list.component';
import { NumberServiceClientComponent } from './number-service-client/number-service.component';

const routes: Routes = [
    {
        path: 'authorizations', component: AuthorizationComponent, children: [
            { path: '', component: OptionsComponent },
            { path: 'users', component: UsersComponent },
            { path: 'abonnement', component: AbonnementComponent },
            { path: 'forfait', component: ForfaitComponent },
            { path: 'numero-abonnement', component: NumberListComponent },
            { path: 'numero-service-client', component: NumberServiceClientComponent },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthorizationRoutingModule { }
