import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class HelperFunctions {
    convertToFrenchTime(timeString: string): string {
        // Diviser l'heure, les minutes et AM/PM
        const [time, meridiem] = timeString.split(' ');
        const [hours, minutes] = time.split(':').map(Number);

        // Convertir l'heure au format 24 heures
        let hour24 = hours;
        if (meridiem === 'PM' && hours < 12) {
            hour24 = hours + 12;
        } else if (meridiem === 'AM' && hours === 12) {
            hour24 = 0;
        }

        // Formatage de l'heure en français
        const formattedTime = `${hour24.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        return formattedTime;
    }

    generateHours(hours:string[]) {
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const formattedHour = this.formatHour(hour, minute);
                hours.push(formattedHour);
            }
        }
    }
    
    formatHour(hour: number, minute: number): string {
        const hourStr = hour < 10 ? '0' + hour : '' + hour;
        const minuteStr = minute === 0 ? '00' : '' + minute;
        return hourStr + ':' + minuteStr;
    }

    // formatTimeToHourMinute(date: Date): string {
    //     const hour = this.padZero(date.getHours());
    //     const minute = this.padZero(date.getMinutes());
    //     return `${hour}:${minute}`;
    // }
    
    // padZero(num: number): string {
    //     return num < 10 ? `0${num}` : num.toString();
    // }


    formatTimeToHourMinute(timeString: string): string {
        // Séparation de l'heure et des minutes
        const [hour, minute] = timeString.split(':').map(Number);
        
        // Ajouter un 0 devant l'heure si elle est inférieure à 10
        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
        
        // Ajouter un 0 devant les minutes si elles sont inférieures à 10
        const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
    
        // Retourner l'heure formatée
        return `${formattedHour}:${formattedMinute}`;
    }

    formatTimeToString(timeString: string): string {
        const [hour, minute] = timeString.split(':').slice(0, 2); // Ignorer les secondes si elles sont présentes
        return `${hour}:${minute}`;
    }

    getLibelleFromStatus(status: string) {
        switch (status) {
        case 'new_created':
            return 'Nouveau PDV';
        case 'incomplete':
            return 'Documents Incomplets';
        case 'disabled':
            return 'Désactivé';
        case 'expired':
            return 'Abonnement Expiré';
        case 'deleted':
            return 'Supprimé';
        case 'actif':
            return 'Actif';
        case 'partner':
            return 'Partenaire';
        default:
            return '';
        }
    }

    getStatusClass(status: string): string {
        switch (status) {
        case 'new_created':
            return 'new-created-color';
        case 'incomplete':
            return 'incomplete-color';
        case 'disabled':
            return 'disabled-color';
        case 'expired':
            return 'expired-color';
        case 'deleted':
            return 'deleted-color';
        case 'actif':
            return 'actif-color';
        case 'partner':
            return 'partner-color';
        default:
            return '';
        }
    }
}