<nav class="navbar top mb-5 scroll-shadow">
    <div class="container">
        <div class="d-flex text-white">
            <h5 class="back" (click)="goBack()"><i  class="bi bi-arrow-left-circle me-2 "></i>Retour</h5>
        </div>
        <div class="d-flex logo">
            <h4>uve<span style="color: #fff;">on</span></h4>
        </div>
        <div class="d-flex align-items-center">
            <mat-icon class="text-white ficon" matTooltip="Nouvel abonement détecté. Cliquez pour voir" (click)="goToAbonnement()" aria-hidden="false" aria-label="Example home icon">notification_important</mat-icon>
            <mat-icon class="text-white ficon" [matMenuTriggerFor]="menu" aria-hidden="false" aria-label="Example home icon">account_circle</mat-icon>
            <mat-menu  #menu="matMenu">
                <button (click)="logout()" style="color: #19265D; font-weight: bold;" mat-menu-item>Se déconnecter</button>
                <button  style="color: #19265D; font-weight: bold;" mat-menu-item>Profile</button>
                <button  style="color: #19265D; font-weight: bold;" mat-menu-item>Settings</button>
            </mat-menu>
            <h5 class="text-white">{{session.user.name}}</h5>
        </div>

    </div>
</nav>