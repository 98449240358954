import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { NotificationService } from 'src/app/api/notification.service';
import { SalepointService } from '../../../../api/salepoint.service';
import { catchError, tap } from 'rxjs';
import { SettingsService } from 'src/app/api/setting.service';

@Component({
    selector: 'app-second-step',
    templateUrl: './information-agence.component.html',
    styleUrls: ['./information-agence.component.scss']
})
export class InformationAgenceComponent implements OnInit {
    zones?: string[]
    filteredZones: string[] = []
    position: string | undefined;
    selectedImage?: File
    webcam?: string = 'assets/img/webcam.png';
    master: boolean = true;
    masterUveon: boolean = false;

    nameAgence?: string
    pdvId?: number
    masterName?: string
    adresse?: string
    emplacement?: string
    uveOnCode?: number
    uveOnCode2?: number

    constructor(
        private pdvService: SalepointService,
        private notificationService: NotificationService,
        private renderer: Renderer2,
        private settingsService: SettingsService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.settingsService.settings$.subscribe((data) => {
            if (data) {
                this.zones = data.zones
                this.filteredZones = data.zones
            }
        });

        this.uveOnCode = this.getRandomID()
    }

    getRandomID(): number {
        const S4 = () => {
            return ((1 + Math.random()) * 0x10000) | 0;
        };
        return S4() + S4();
    }
    filterZones(val: Event) {
        const value: string = (val.target as HTMLInputElement).value
        if (!value) {
            this.filteredZones = this.zones!.slice();
        } else {
            const filterValue = value.toLowerCase();
            this.filteredZones = this.zones!.filter(zone => zone.toLowerCase().includes(filterValue));
        }
    }

    openFileExplorer() {
        document.getElementById('file-input')!.click();
    }

    onFileSelected(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const fileNameSpan = document.getElementById('file-name-placeholder');
        const label = document.getElementById('place');

        if (inputElement.files && inputElement.files.length > 0) {
            const fileName = inputElement.files[0].name;

            this.selectedImage = inputElement.files[0];

            this.addfileName(fileNameSpan as HTMLElement, label as HTMLElement, fileName)
            this.cdr.detectChanges();
        }
    }


    getObjectURL(file: File): string {
        return URL.createObjectURL(file);
    }

    addWebCamFileName(fileNameSpan: HTMLElement, label: HTMLElement, imageData: string) {
        fileNameSpan.innerText = 'Nom du fichier';
        this.webcam = imageData;
        label.style.display = 'block';
    }

    addfileName(fileNameSpan: HTMLElement | null, label: HTMLElement | null, fileName: string) {

        if (fileNameSpan && label) {
            fileNameSpan.textContent = fileName;
            label.textContent = ""
        }
        else {
            if (fileNameSpan) {
                fileNameSpan.textContent = '';
            }
        }
    }



    next() {
        this.pdvService.sendData({ step: 2, pdvId: this.pdvId })
    }


    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude.toFixed(6); // Formater la latitude avec 6 décimales
                    const longitude = position.coords.longitude.toFixed(6); // Formater la longitude avec 6 décimales
                    this.position = `${latitude}, ${longitude}`;
                },
                (error) => {
                    console.error('Erreur de géolocalisation : ', error);
                }
            );
        } else {
            console.error('La géolocalisation n\'est pas prise en charge par votre navigateur.');
        }
    }

    takePhoto() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            console.error('getUserMedia not supported in this browser');
            return;
        }

        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                const videoElement = this.renderer.createElement('video');
                this.renderer.setProperty(videoElement, 'srcObject', stream);
                this.renderer.appendChild(document.body, videoElement);
                videoElement.play();

                setTimeout(() => {
                    const canvas = this.renderer.createElement('canvas');
                    canvas.width = videoElement.videoWidth;
                    canvas.height = videoElement.videoHeight;

                    const context = canvas.getContext('2d');
                    context?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                    const imageData = canvas.toDataURL('image/png');
                    const fileNameSpan = document.getElementById('file-name-placeholder');
                    const label = document.getElementById('place');
                    this.addWebCamFileName(fileNameSpan as HTMLElement, label as HTMLElement, imageData);

                    stream.getTracks().forEach((track) => track.stop());
                    this.renderer.removeChild(document.body, videoElement);
                }, 1000);
            })
            .catch((error) => {
                console.error('Error accessing camera:', error);
            });
    }

    sendDatas() {
        console.log("photo_front: ", this.selectedImage);
        const data = new FormData();
        data.append('gps_position', this.position!);
        data.append('photo_front', this.selectedImage!);
        data.append('name', this.nameAgence!);
        data.append('zone', this.adresse!);
        data.append('street', this.emplacement!);

        if (!this.master) {

            if (!this.uveOnCode2) {
                this.notificationService.showError("Entrez le code de votre parrain")
            }
            data.append('master_code', String(this.uveOnCode2));
            data.append('name_master', "");
        }
        else {
            data.append('master_code', String(this.uveOnCode));
            data.append('name_master', this.masterName!);
            // todo: boolean send like string
            data.append('is_master', 'true');
        }

        // todo: boolean send like string
        if(this.masterUveon){
            data.append('is_uveon_master', 'true')
        }else{
            data.append('is_uveon_master', 'false')
        }

        if (!this.selectedImage) {
            this.notificationService.showError("Veuillez ajouter la photo de l'agence")
        } else {
            this.pdvService.createPDV(data).pipe(
                tap((response) => {
                    this.notificationService.showSuccess(response.message);
                    this.pdvId = response.data.id
                    this.next()
                }),
                catchError((errorMessage) => {
                    this.notificationService.showError(errorMessage);
                    throw errorMessage;
                })
            ).subscribe();
        }



    }
}
