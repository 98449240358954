import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs';

import { NotificationService } from 'src/app/api/notification.service';
import { ForfaitService } from 'src/app/api/forfait.service';
import { ISubsTel } from 'src/app/Types/sub-tels';
import { SettingsService } from 'src/app/api/setting.service';
import { IOperateur } from 'src/app/Types/operateurs';
import { SubscriptionTelService } from 'src/app/api/subscription-tels.service';

@Component({
    selector: 'app-add-subsTel-dialog',
    templateUrl: './add-subsTel-dialog.component.html',
    styleUrls: ['./add-subsTel-dialog.component.scss']
})
export class AddSubsTelDialogComponent implements OnInit {
    substelForm: FormGroup = this._formBuilder.group({
        operateur: ['', [Validators.required]],
        tel: ['', [Validators.required]],
        //created_at: ['' ],
        //updated_at: [''],
     
    });
    roles: any;
    document_type: any[] = [];
    lockDuringSending = false;
    operateurs:IOperateur[]=[]
    initialSubsTel: ISubsTel;
    sendTitle: string = '';
    constructor(
        public matDialogRef: MatDialogRef<AddSubsTelDialogComponent>,
        private _formBuilder: FormBuilder,
        private subsTelService:SubscriptionTelService,
        private settingsService: SettingsService,
        private notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) public substel: ISubsTel,
    ) {
        this.initialSubsTel = substel;
    }

    ngOnInit(): void {
        this.getSettings()

        if (this.initialSubsTel) {
            this.initForfaitForm(this.initialSubsTel);
            this.sendTitle = 'Modiffier';


        } else {
            this.sendTitle = 'Enregister';
            

        }
    }


    getSettings() {
        this.settingsService.settings$.subscribe((data) => {
                this.operateurs = data.operateurs
           
        });
    }

    /**
     * Formulaire de modification
     */
    initForfaitForm(subsTel: ISubsTel): void {
        this.substelForm.get('operateur')?.setValue(subsTel.operateur);
        this.substelForm.get('tel')?.setValue(subsTel.tel);
        //this.substelForm.get('created_at')?.setValue(subsTel.created_at);
        //this.substelForm.get('updated_at')?.setValue(subsTel.updated_at);

    }



    post(formData: any) {        
        this.subsTelService.createSubsTels(formData).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.matDialogRef.close(true);
            }),
            catchError((fail) => {
                
                this.notificationService.showError(fail.error.message);
                this.lockDuringSending = false;
                throw fail;
            })
        ).subscribe()
    }

    update(formData: any) {

        this.subsTelService.updateSubTels(this.initialSubsTel.id as number, formData).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.matDialogRef.close(true);
            }),
            catchError((fail) => {
                this.notificationService.showError(fail.error.message);
                this.lockDuringSending = false;
                throw fail;
            })
        ).subscribe()
    }

    

    send() {
        this.lockDuringSending = true;

        const formData = { ...this.substelForm.value };
       

        if (this.initialSubsTel) {
            //formData.updated_at=new Date()
            return this.update(formData);
        }
       //formData.created_at=new Date()
        return this.post(formData); 

    }
}
