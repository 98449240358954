import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataSharingService } from 'src/app/api/base/data.sharing.service';
import { LoaderService } from 'src/app/api/base/loader.service';
import { SettingsService } from 'src/app/api/setting.service';
import { NotificationService } from 'src/app/api/notification.service';

@Component({
    selector: 'app-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
    rayon: number = 0;
    selectedStatut: string = '';
    zones: string[] | undefined = [];
    selectedZone?: string;
    filteredZones: string[] = []
    statusOptions: { label: string, value: string, color: string }[] = [
        { label: 'pas encore validés', value: 'not_valide', color: 'disabled' },
        { label: 'inactifs', value: 'inactifs', color: 'warn' },
        { label: 'documents incomplets', value: 'incomplete', color: 'warn' },
        { label: 'désactivés', value: 'disabled', color: 'warn' },
        { label: 'abbonnement expiré', value: 'expired', color: 'accent' },
        { label: 'nouveau', value: 'new_created', color: 'primary' },
        { label: 'actifs', value: 'actif', color: 'primary' }
    ];
    selectedSalepoint: string = '';
    iconClass: string = 'bi bi-geo-fill';
    @Input() zone: boolean = false;
    searchLock: boolean = false;
    gps_position: string = '';
    constructor(
        private settingsService: SettingsService,
        private notificationService: NotificationService,
        private loaderService: LoaderService,
        private dataSharingService: DataSharingService
    ) { }

    ngOnInit(): void {
        this.getSettings();
    }


    updateSearchData(): void {
        const searchData = {
            'rayon': this.rayon,
            'status': this.selectedStatut,
            'gps_position': this.gps_position,
            'name': this.selectedSalepoint,
            'zone': this.selectedZone,
        };

        this.dataSharingService.setSearchData(searchData);
    }

    updateStatus(selectedStatus: string): void {
        this.selectedStatut = selectedStatus;
        this.updateSearchData();
    }

    localization() {
        if (!this.searchLock) {
            if (navigator.geolocation) {
                const position$ = new Observable<GeolocationPosition>((observer) => {
                    navigator.geolocation.getCurrentPosition((positioon) => {
                        observer.next(positioon)
                        observer.complete()
                        console.log(positioon);
                    });
                });

                position$.subscribe((position => {
                    this.gps_position = `${position.coords.latitude},${position.coords.longitude}`;
                    this.updateSearchData();
                }))
            }
        }

        // changement des icones
        this.searchLock = !this.searchLock;
        this.iconClass = this.searchLock ? 'bi bi-geo-alt-fill' : 'bi bi-geo-fill';
    }

    filterZones(val: Event) {
        const value: string = (val.target as HTMLInputElement).value
        if (!value) {
            this.filteredZones = this.zones!.slice();
        } else {
            const filterValue = value.toLowerCase();
            this.filteredZones = this.zones!.filter(zone => zone.toLowerCase().includes(filterValue));
        }
    }

    getSettings() {
        this.loaderService.showLoader(); // Affiche le loader
        this.settingsService.settings$.subscribe((data) => {
            if (data) {
                this.zones = data.zones;
                this.filteredZones = data.zones;
            }
            this.loaderService.hideLoader(); // Masque le loader
        });
    }

    updateSetting() {
        this.updateSearchData();
    }

    formatLabel(value: number): string {
        return `${value}m`;
    }
}

