import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStorageService } from './helpers/app-storage.service';
import { BaseRepository } from './base/base-repository';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StatService extends BaseRepository {
    constructor(http: HttpClient, appStorageService: AppStorageService) {
        super('statistics', http, appStorageService);
    }

    getStatsList(salepoint_id:number, period: number) {
        return this.http.get<any>(this.url +this.controllerName + "/" + salepoint_id + "/" + period, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }
}
