import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { catchError, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/api/notification.service';
import { IRoles } from 'src/app/Types/roles';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { SubscriptionTelService } from 'src/app/api/subscription-tels.service';
import { ISubsTel } from 'src/app/Types/sub-tels';
import { AddSubsTelDialogComponent } from '../../dialogs/add-subsTel-dialog/add-subsTel-dialog.component';
@Component({
    selector: 'number-list',
    templateUrl: './number-list.component.html',
    styleUrls: ['./number-list.component.scss']
})
export class NumberListComponent implements OnInit {

    dataSource: ISubsTel[] = [];
    substels: ISubsTel[] = [];
    title: string = '';
    roles: IRoles[] = [];
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

    constructor(
        private subcribtionTelService: SubscriptionTelService,
        public dialog: MatDialog,
        public notificationService: NotificationService,
    ) { }

    ngOnInit(): void {
        this.subcribtionTelService.getSubscriptionTels().pipe(
            tap((response) => {
                this.title = "Liste des numeros d'abonnement";
                this.dataSource = response.data
                this.substels = response.data
            }),
            catchError((error) => {
                throw error;
            })
        ).subscribe()


    }



    addNewNum() {
        const dialogRef = this.dialog.open(AddSubsTelDialogComponent, { width: '50%',
        height: '55%'
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.ngOnInit();
        });
    }


    delete(subsTel: ISubsTel) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
            {
                width: '25%',
                data: {
                    id: subsTel.id,
                    class: 'bg-danger text-white text-bold',
                    message: 'Suppression du numéro ' + subsTel.tel
                }
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.subcribtionTelService.deletesubstel(subsTel.id!).pipe(
                    tap((response) => {
                        this.notificationService.showSuccess(response.message);
                        this.ngOnInit();
                    }),
                    catchError((fail) => {
                        this.notificationService.showError(fail.error.message);
                        throw fail;
                    })
                ).subscribe()

            } else {
                return;
            }
        });

    }

    edit(subsTel: ISubsTel) {
        console.log(subsTel);
        const dialogRef = this.dialog.open(
            AddSubsTelDialogComponent,
            { width: '50%',
            height: '55%'
            ,data: subsTel });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            this.ngOnInit();
        });
    }

   
}