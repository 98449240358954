import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './Modules/dashboard/main-menu/main-menu.component';
import { AuthentificationComponent } from './Modules/authentification/authentification.component';
import { PortailComponent } from './Modules/portail/portail.component';
import { SignUpComponent } from './Modules/sign-up/sign-up.component';
import { AuthGuard } from './api/guards/auth.guard';
import { ZoneComponent } from './Modules/dashboard/zone/zone.component';
import { PdvFormComponent } from './Modules/dashboard/pdv-form/pdv-form.component';
import { GuestGuard } from './api/guards/guest.guard';

const routes: Routes = [
    { path: '', component: PortailComponent, canActivate:[GuestGuard]},
    { path: 'connexion', component: AuthentificationComponent, canActivate:[GuestGuard]},
    { path: 'inscription', component: SignUpComponent , canActivate:[GuestGuard]},
    { path: 'accueil', component: HomeComponent , canActivate: [AuthGuard] },
    { path: 'zone', component: ZoneComponent, canActivate: [AuthGuard] },
    { path: 'pdv-form', component: PdvFormComponent, canActivate: [AuthGuard] },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
