import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStorageService } from './helpers/app-storage.service';
import { BaseRepository } from './base/base-repository';

@Injectable({
    providedIn: 'root',
})
export class MenuService extends BaseRepository {
    constructor(http: HttpClient, appStorageService: AppStorageService) {
        super('menu', http, appStorageService);
    }
}
