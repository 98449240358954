<div class="row">
    <div class="col-md-6 col-sm-12 mt-4">
        <!-- <div class="title">
            <h5><i class="bi bi-people-fill "></i> Code uveon</h5>
        </div> -->

        <div class="title mt-2">
            <h5><i class="bi bi-bank2"></i> Information agence*</h5>
        </div>
        <div class="mb-2">
            <!-- <input  class="ps-3" type="checkbox" > -->
            <!-- <label for="">&nbsp;Je suis master</label> -->
            <section class="example-section">
                <mat-checkbox color="primary" [(ngModel)]="master" class="ps-3 example-margin">Je suis
                    master</mat-checkbox>
                <mat-checkbox color="primary" [(ngModel)]="masterUveon" class="ps-3 example-margin">Je suis master
                    UVEON</mat-checkbox>
            </section>
        </div>
        <input *ngIf="master || masterUveon" class="text w-100 ps-3 mb-3" type="text" [(ngModel)]="uveOnCode" readonly>

        <input *ngIf="master || masterUveon" class="text w-100 ps-3 mb-3" [(ngModel)]="masterName" type="text" placeholder="Nom master">

        <input *ngIf="!master && !masterUveon" class="text w-100 ps-3 mb-3" type="text" placeholder="Code master" [(ngModel)]="uveOnCode2">
        <div class="file-upload w-100">
            <input id="file-input" type="file" (change)="onFileSelected($event)">
            <label id="place" for="file-input">Prendre la photo de l’agence*</label>
            <span id="file-name-placeholder"></span>

            <!-- icone web cam -->
            <button type="button" class="camera-icon" (click)="takePhoto()">
                <i class="bi bi-camera-fill"></i>
            </button>

            <button type="button" class="folder-icon" (click)="openFileExplorer()">
                <i class="bi bi-file-earmark-arrow-down-fill"></i>
            </button>

        </div>
        <img id="place" [src]="selectedImage ? getObjectURL(selectedImage) : webcam" width="300" height="150" alt="picture from webcam" class="mt-2" (click)="takePhoto()">
    </div>




    <div class="col-md-6 col-sm-12">
        <div class="fond">
            <!-- <div class="input-container">
                <input [(ngModel)]="position" class="text ps-3 mb-3 w-100" type="text" placeholder="GPS" readonly>
                <button type="button" class="icon-button" (click)="getLocation()">
                    <i class="bi bi-crosshair2"></i>
                </button>
            </div> -->
            <mat-form-field appearance="outline" class="mb-3 w-100">
                <mat-label>Coordonnées GPS</mat-label>
                <input matInput placeholder="Coordonnées GPS" class="text-black" [(ngModel)]="position" readonly>

                <button mat-icon-button matSuffix matTooltip="position gps" matTooltipPosition="above"
                    (click)="getLocation()">
                    <div class="icon">
                        <i class="bi bi-crosshair2"></i>
                    </div>
                </button>
            </mat-form-field>


        <input class="text mb-3 w-100 p-3" [(ngModel)]="nameAgence" type="text" placeholder="Nom de mon agence">

        <div class="mb-3">
            <mat-form-field class="example-full-width mb-3 zonefield w-100" appearance="outline">
                <mat-label>Adresse (Ville/Commune)</mat-label>
                <input type="text" matInput [(ngModel)]="adresse" required [matAutocomplete]="autoZone"
                    (input)="filterZones($event)">
                <mat-autocomplete #autoZone="matAutocomplete">

                    <mat-option *ngFor="let zone of filteredZones" [value]="zone">{{zone}}</mat-option>

                </mat-autocomplete>

            </mat-form-field>
        </div>

        <input class="text mb-3 w-100 p-3" type="text" [(ngModel)]="emplacement" placeholder="Emplacement précis">
        <p>Ex: Sopim, à côté du terrain inch’allah</p>
        <div class="mt-5 d-flex justify-content-start">


            <button class="btn w-100" (click)="sendDatas()" type="submit" [disabled]="!nameAgence">
                Suivant <i class="bi bi-caret-right-fill"></i>
            </button>
        </div>
    </div>
</div>

</div>