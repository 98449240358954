<div class="wrapper fadeInDown">
  <div id="formContent">
    <!-- Icon -->
    <div class="fadeIn first logo">
      <img src="../../assets/img/logo.jpeg" width="90px" height="90px" alt="User Icon" />
      <h1>uve<span style="color: #06D9FD;">on</span></h1>
    </div>

    <!-- Login Form -->
    <form [formGroup]="form">
      <input type="tel" formControlName="tel" id="login" class="fadeIn second" name="login" placeholder="identifiant">

      <input type="password" formControlName="password" id="password" class="fadeIn third" name="login"
        placeholder="mot de passe">

      <!-- <p class="text-danger" *ngIf="form.get('password')?.hasError('required')">
            Entrez votre mot de passe svp
          </p> -->

      <input (click)="login()" type="submit" class="fadeIn fourth" value="Connexion" [disabled]="!form.valid || isSubmitting">

    </form>
    <!-- Remind Passowrd -->
    <div id="formFooter">
      <a class="underlineHover" href="#">Mot de passe oublié?</a>
    </div>
  </div>
</div>