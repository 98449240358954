import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
    override itemsPerPageLabel = 'Éléments par page';
    override nextPageLabel = 'Page suivante';
    override previousPageLabel = 'Page précédente';
    override firstPageLabel = 'Première page'; 
    override lastPageLabel = 'Dernière page'; 

    override getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 sur ${length}`;
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        const endIndex =
			startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
}