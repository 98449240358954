import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SalepointService } from '../../../api/salepoint.service';

@Component({
    selector: 'app-pdv-form',
    templateUrl: './pdv-form.component.html',
    styleUrls: ['./pdv-form.component.scss']
})
export class PdvFormComponent {
    step: any = 1;
    message:string="next";

    constructor(private _formBuilder: FormBuilder,
    private pdvService:SalepointService) { 
        this.pdvService.commonMessage.subscribe(m=>{
            console.log("dsdsdsdsds",m);
            this.step=m.step
        })
    }
}
