<div class="card-group">
	<div class="col-md-3 col-sm-12" *ngFor="let salepoint of salepoints">
		<div class="card m-2">
			<img [src]="salepoint.photo_front" width="100%" style="max-height: 500px; min-height: 400px;" class="card-img-top" alt="Photo de pdv non fournie" role="button" (click)="navigateToDetail(salepoint)"/>
			<div class="position-absolute" *ngIf="salepoint.is_master" style="top: 1%; left: 1%;" matTooltip="Master">
				<button mat-mini-fab color="primary">
					<mat-icon>account_balance</mat-icon>
				</button>
			</div>
			<div class="position-absolute" *ngIf="salepoint.is_uveon_master" style="top: 1%; left: 10%;" matTooltip="Master Uveon">
				<button mat-mini-fab color="success">
					<mat-icon>account_balance</mat-icon>
				</button>
			</div>

			<div class="position-absolute" style="top: 1%; right: 1%;" *ngIf="salepoint.status != 'partner'">
				<div class="badge rounded text-start bg-white badge-shadow px-3 py-2">
					<mat-icon class="text-success" style="vertical-align: bottom;">check_circle</mat-icon>
					<span class="text-dark" style="vertical-align: super;">2/2</span>
				</div>
			</div>

			<div class="position-absolute" *ngIf="salepoint.status != 'partner'" style="bottom: 50%; left: 1%;">
				<span class="badge rounded text-start badge-shadow text-bg-danger px-3 py-2">
					Revue par Albert <br>
					10-10-2024 à 17h20
				</span>
			</div>

			<div class="position-absolute" *ngIf="salepoint.status" style="bottom: 50%; right: 1%;">
				<span [ngClass]="getClassFromStatus(salepoint.status)" class="badge text-bg-danger rounded text-start badge-shadow px-3 py-2">
					{{libelleFromStatus(salepoint.status)}}
				</span>
			</div>

			<div class="card-body">
				<h5 class="card-title text-center">{{salepoint.name}}</h5>
				<hr>
				<p class="card-text" >Gerant: {{salepoint.manager}}</p>
				
				<hr>
				<p class="card-text">
					<small class="text-muted">Créé le: {{ salepoint.created_at | date:'dd MMMM yyyy à HH:mm' }}</small>
					<small class="text-muted"> Par: {{ salepoint.createdBy }}</small>
				</p>
			</div>
		</div>
	</div>
</div>