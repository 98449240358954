<mat-dialog-content class="custom-dialog-width">
	<h3 class="text-center mb-5">{{pdv_name}}</h3>
	<h5 class="mb-3 text-center">L'abonnement expirera dans 30 jours</h5>
	<div style="height: 30vh; overflowY: scroll; overflowX: auto">
		<table class="table table-striped">
			<thead  >
				<tr >
					<th scope="col">Date de réabonnement</th>
					<th scope="col">Numero</th>
					<th scope="col">Date de création</th>
					<th scope="col">Forfait</th>
					<th scope="col">Validé</th>
				</tr>
			</thead>
			<tbody >
				<tr style="cursor: pointer;" *ngFor="let sub of subsList">
					<td>{{sub.updated_at | date:'dd/MM/yyyy'}}</td>
					<td>{{sub.number}} </td>
					<td>{{sub.created_at| date:'dd/MM/yyyy'}} </td>
					<td>{{sub.new_duration}} </td>
					<td>
                        <p-checkbox (click)="changeSubState(sub)"  [(ngModel)]="sub.validated" [binary]="true" inputId="binary"></p-checkbox>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	

<mat-dialog-content>

<mat-dialog-actions class="justify-content-evenly">

	<div class="row p-4">
		<button class="col-md-6" style="width: 100px;" mat-fab extended (click)="matDialogRef.close()">
			Fermer
		</button>
	</div>
</mat-dialog-actions>