import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs';
import { IUsers } from 'src/app/Types/users';
import { RoleService } from 'src/app/api/role.service';
import { SettingsService } from 'src/app/api/setting.service';
import { UserService } from 'src/app/api/user.service';
import { NotificationService } from 'src/app/api/notification.service';

@Component({
    selector: 'app-add-user-dialog',
    templateUrl: './add-user-dialog.component.html',
    styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
    userForm: FormGroup = this._formBuilder.group({
        name: ['', [Validators.required]],
        last_name: ['', [Validators.required]],
        tel: ['', [Validators.required]],
        type_identity: ['', [Validators.required]],
        num_identity: ['', [Validators.required]],
        role_id: [''],
        password: [''],
    });
    roles: any;
    document_type: any[] = [];
    lockDuringSending = false;
    initialUser: IUsers;
    sendTitle: string= '';
    constructor(
        public matDialogRef: MatDialogRef<AddUserDialogComponent>,
        private _formBuilder: FormBuilder,
        private roleService: RoleService,
        private userService: UserService,
        private notificationService: NotificationService,
        private settingsService: SettingsService,
        @Inject(MAT_DIALOG_DATA) public user: IUsers,
    ) {
        this.initialUser = user;
    }

    ngOnInit(): void {
        this.getRoles();
        this.getSettings();

        if (this.initialUser) {
            this.initUserForm(this.initialUser);
            this.sendTitle = 'Modiffier';
        }else{
            this.sendTitle = 'Enregister';
            this.userForm.patchValue({
                password: this.generatePassword()
            });
        }
    }

    /**
     * Formulaire de modification
     */
    initUserForm(user: IUsers): void {
        this.userForm.get('name')?.setValue(user?.name);
        this.userForm.get('last_name')?.setValue(user?.last_name);
        this.userForm.get('tel')?.setValue(user?.tel);
        this.userForm.get('type_identity')?.setValue(user?.type_identity);
        this.userForm.get('num_identity')?.setValue(user?.num_identity);
        this.userForm.get('role_id')?.setValue(user?.role_id);
    }

    getSettings() {
        this.settingsService.settings$.subscribe((data) => {
            if (data) {
                this.document_type = Object.keys(data.document_type).map((key) => {
                    return { key: key, label: data.document_type[key] };
                });
            }
        });
    }

    generatePassword(): string {
        const password = Math.floor(1000 + Math.random() * 9000).toString();
        return password;
    }

    getRoles() {
        this.roleService.getAll().pipe(
            tap((response) => this.roles = response.data),
            catchError((error) => {
                throw error;
            })
        ).subscribe()
    }

    post(formData:any){
        this.userService.add(formData).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.matDialogRef.close(true);
            }),
            catchError((fail) => {
                this.notificationService.showError(fail.error.message);
                this.lockDuringSending = false;
                throw fail;
            })
        ).subscribe()
    }

    update(formData: any) {
        this.userService.update(this.initialUser.id as number, formData).pipe(
            tap((response) => {
                this.notificationService.showSuccess(response.message);
                this.matDialogRef.close(true);
            }),
            catchError((fail) => {
                this.notificationService.showError(fail.error.message);
                this.lockDuringSending = false;
                throw fail;
            })
        ).subscribe()
    }

    send() {
        this.lockDuringSending = true;

        const formData = { ...this.userForm.value };

        formData.tel = `${this.userForm.value.tel}`;

        console.log(formData);

        if (this.initialUser) {
            return this.update(formData);
        }
        return this.post(formData);

    }
}
