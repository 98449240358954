<div class="row">
	<!-- <div class="col-md-3 option">
		<div class="icon">
			<i class=" bi bi-gear"></i>
		</div>
		<h4>Droit <br>
			Administrateur</h4>
	</div> -->
	<div class="col-md-3 option" routerLink="users">
		<div class="icon">
			<i class="bi bi-person-gear"></i>
		</div>
		<h4>Gestion<br>
			Des utilisateurs</h4>
	</div>
	
	<div class="col-md-3 option" routerLink="abonnement">
		<div class="icon">
			<i class=" bi bi-collection-play-fill"></i>
		</div>
		<h4>Validation<br>
			Abonnement</h4>
	</div>

	<div class="col-md-3 option" routerLink="forfait">
		<div class="icon ">
			<i class="bi bi-node-plus-fill"></i>
		</div>
		<h4>Forfaits</h4>
	</div>

	<div class="col-md-3 option">
		<div class="icon">
			<i class="bi bi-cart-check-fill"></i>
		</div>
		<h4>Déblocage<br>
			Services et Niveaux</h4>
	</div>

	<div class="col-md-3 option" routerLink="numero-abonnement">
		<div class="icon">
			<i class="bi bi-list-ol"></i>
		</div>
		<h4>Numéros<br>
			d'abonnement</h4>
	</div>

	<div class="col-md-3 option" routerLink="numero-service-client">
		<div class="icon">
			<i class="bi bi-telephone-outbound-fill"></i>
		</div>
		<h4>Numéros<br>
			du service client</h4>
	</div>

	

	
</div>