<app-header></app-header>
<div class="content">

    <div *ngIf="step===1" class="container">
        <div class="sub">
            <div class="col-">
                <div class="icon-light">
                    <i class="bi bi-people-fill "></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class=" bi bi-bank2"></i>
                </div>
            </div>
            <!-- <div class="col-">
                    <div class="icon-dark">
                        <i class="bi bi-bank"></i>
                    </div>
                </div> -->
            <div class="col-">
                <div class="icon-dark">
                    <i class="bi bi-wallet2"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class=" bi bi-gear-fill"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class="bi bi-clock"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class=" bi bi-camera-fill"></i>
                </div>
            </div>
        </div>
        <app-second-step></app-second-step>

    </div>
    <div *ngIf="step===2" class="container">
        <div class="sub">
            <div class="col-">
                <div class="icon-light">
                    <i class="bi bi-people-fill "></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-light">
                    <i class=" bi bi-bank2"></i>
                </div>
            </div>
            <!-- <div class="col-">
                    <div class="icon-dark">
                        <i class="bi bi-bank"></i>
                    </div>
                </div> -->
            <div class="col-">
                <div class="icon-dark">
                    <i class="bi bi-wallet2"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class=" bi bi-gear-fill"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class="bi bi-clock"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class=" bi bi-camera-fill"></i>
                </div>
            </div>
        </div>
        <app-first-step></app-first-step>
    </div>

    <div *ngIf="step===4" class="container">
        <div class="sub">
            <div class="col-">
                <div class="icon-light">
                    <i class="bi bi-people-fill "></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-light">
                    <i class=" bi bi-bank2"></i>
                </div>
            </div>
            <!-- <div class="col-">
                    <div class="icon-light">
                        <i class="bi bi-bank"></i>
                    </div>
                </div> -->
            <div class="col-">
                <div class="icon-light">
                    <i class="bi bi-wallet2"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class=" bi bi-gear-fill"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class="bi bi-clock"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class=" bi bi-camera-fill"></i>
                </div>
            </div>
        </div>
        <app-forth-step></app-forth-step>
    </div>








    <div *ngIf="step===5" class="container">
        <div class="sub">
            <div class="col-">
                <div class="icon-light">
                    <i class="bi bi-people-fill "></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-light">
                    <i class=" bi bi-bank2"></i>
                </div>
            </div>
            <!-- <div class="col-">
                    <div class="icon-light">
                        <i class="bi bi-bank"></i>
                    </div>
                </div> -->
            <div class="col-">
                <div class="icon-light">
                    <i class="bi bi-wallet2"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-light">
                    <i class=" bi bi-gear-fill"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class="bi bi-clock"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class=" bi bi-camera-fill"></i>
                </div>
            </div>
        </div>
        <app-fif-step></app-fif-step>
    </div>
    <div *ngIf="step===6" class="container">
        <div class="sub">
            <div class="col-">
                <div class="icon-light">
                    <i class="bi bi-people-fill "></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-light">
                    <i class=" bi bi-bank2"></i>
                </div>
            </div>
            <!-- <div class="col-">
                    <div class="icon-light">
                        <i class="bi bi-bank"></i>
                    </div>
                </div> -->
            <div class="col-">
                <div class="icon-light">
                    <i class="bi bi-wallet2"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-light">
                    <i class=" bi bi-gear-fill"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-light">
                    <i class="bi bi-clock"></i>
                </div>
            </div>
            <div class="col-">
                <div class="icon-dark">
                    <i class=" bi bi-camera-fill"></i>
                </div>
            </div>
        </div>
        <app-sixth-step></app-sixth-step>
    </div>

</div>