<div class="text-center mb-2">
    <input class="mx-3" type="text" [(ngModel)]="name" pInputText placeholder="Nom de l’agence " />
    <input class="mx-3" pInputText pKeyFilter="int" [(ngModel)]="tel" placeholder="Compte mobile money" />
    <input class="mx-3" type="text" pInputText placeholder="Master" [(ngModel)]="master" />
    <button (click)="search()" class="btn" style="background-color: #06D9FD; padding: 10px 15px;">Rechercher</button>

    <button class="ms-3" (click)="loadData()" mat-mini-fab color="primary" matTooltip="Actualiser">
        <mat-icon>refresh</mat-icon>
    </button>
</div>
<p-table *ngIf="agencies.length!=0" #dt styleClass="p-datatable-sm p-datatable-gridlines" [value]="agencies" responsiveLayout="scroll" [rows]="10"
    [globalFilterFields]="['name', 'tel']" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 20, 30]"
    [showCurrentPageReport]="true" currentPageReportTemplate="De {first} à {last} sur {totalRecords}"
    selectionMode="single" [rowHover]="true" dataKey="id">

    <ng-template pTemplate="header">
        <tr>
            <th class="head" pSortableColumn="name">
                Nom de l'agence
                <p-sortIcon field="name"></p-sortIcon>
            </th>

            <!-- <th class="head" pSortableColumn="Montant">
                Forfait
                <p-sortIcon field="Montant"></p-sortIcon>
            </th>
            <th class="head" pSortableColumn="Operateur">
                Opérateur
                <p-sortIcon field="Operateur"></p-sortIcon>
            </th> -->
            <th class="head" pSortableColumn="Validation">
                Statut
                <p-sortIcon field="Validation"></p-sortIcon>
            </th>
            <th class="head" pSortableColumn="tel">
                Durée Abonnement
                <p-sortIcon field="tel"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-agency>
        <tr (click)="edit(agency)" class="line">
            <td  style="width: 60%; ">
                <span class="p-column-title">name</span>
                {{ agency.name }}
            </td>

            <!-- <td (click)="edit()" style="width: 30%; ">
                <span class="p-column-title">Montant</span>
                {{ agency.master_code ?? "Aucun forfait" }}
            </td>
            <td (click)="edit()" style="width: 30%; ">
                <span class="p-column-title">Operateur</span>
                
            </td> -->
            <td style="width: 20%; ">
                <p-inputSwitch [(ngModel)]="agency.actif" [disabled]="true"></p-inputSwitch>
            </td>
            <td style="width: 20%; ">
                <span class="p-column-title">tel</span>
                {{ agency.remaining_days }}
            </td>


        </tr>
    </ng-template>
</p-table>
<div class="spinner-container" *ngIf="agencies.length===0">

    <mat-progress-spinner *ngIf="agencies.length===0" [diameter]="50"
        mode="indeterminate"></mat-progress-spinner>

</div>