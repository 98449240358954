import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../api/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../api/notification.service';
import { tap, catchError } from 'rxjs/operators';

@Component({
    selector: 'app-authentification',
    templateUrl: './authentification.component.html',
    styleUrls: ['./authentification.component.scss'],
})
export class AuthentificationComponent {
    form: FormGroup = this.fb.group({
        tel: ['', Validators.required],
        password: ['', Validators.required],
    });
    isSubmitting = false;

    constructor(
        private authService: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private notificationService: NotificationService,
    ) { }

    public login() {
        if (this.form.valid) {
            this.isSubmitting = true;
            this.authService.login(this.form.value.tel, this.form.value.password).pipe(
                tap((res) => {
                    const user = res;
                    this.notificationService.showSuccess(user.message);
                    this.router.navigateByUrl('/accueil');
                }),
                catchError((errorMessage) => {
                    this.notificationService.showError(errorMessage);
                    this.isSubmitting = false;
                    throw errorMessage; // Re-throw the error to be caught by the next error handler
                    
                })
            ).subscribe();
        }
       
    }
}
