<h3 mat-dialog-title class="text-bold text-center">Ajouter un nouveau compte</h3>

<mat-dialog-content class="custom-dialog-width">

    <form class="image-selection-form" [formGroup]="createMasterForm">

        <mat-form-field class="example-full-width mb-3 zonefield mt-2" appearance="outline">
            <mat-label>Opérateur</mat-label>
            <mat-select formControlName="operateur" [(ngModel)]="operateur"  (selectionChange)="loadMasterList()" >
                <mat-option *ngFor="let op of operateurs" [value]="op.name" >
                    {{op.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="mt-3">
            <mat-checkbox color="primary" [(ngModel)]="master" formControlName="master" class="example-margin">Je suis
                master</mat-checkbox>
        </div>
        <mat-form-field *ngIf="!master" class="example-full-width mb-3 zonefield" appearance="outline">
            <mat-label>Master</mat-label>
            <mat-select formControlName="masterId" [(ngModel)]="masterID">
                <input formControlName="Search" (keyup)="onKey()" class="p-3 textSearch w-100"
                    placeholder="Recherher ici...">
                <mat-option *ngFor="let master of filteredMasters" [value]="master.id">
                    {{master.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <label class="mt-3" for="">Renseigne dans <span style="color:#19265D;"><b>“numéro de
                    compte”</b></span><br>
            <b>ton compte principal / ta tête de pond</b>
        </label>
        <input formControlName="tel" class="text" type="text" placeholder="Numéro de compte"
            [readonly]="!operateur">

        <div class="row mt-5">
            <button class="btn bg-danger" (click)="matDialogRef.close(false)">
                Annuler
            </button>

            <button [mat-dialog-close]="true" class="btn bg-success" type="submit" (click)="registerMaster()">
                Créer
            </button>
        </div>
    </form>
    <mat-dialog-content>