import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdvFormComponent } from './pdv-form.component';
import { SheduleComponent } from './shedule/shedules.component';
import { AgencySettingComponent } from './agency-setting/agency-setting.component';
import { InformationAgenceComponent } from './information-agence/information-agence.component';
import { RequiredDocComponent } from './required-doc/required-doc.component';
import { LayoutModule } from 'src/app/Modules/layout/layout.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule, MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon'; 
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AccountNumberComponent } from './account-number/account-number.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MatRadioModule } from '@angular/material/radio';



@NgModule({
    declarations: [
        RequiredDocComponent,
        InformationAgenceComponent,
        AccountNumberComponent,
        AgencySettingComponent,
        PdvFormComponent,
        SheduleComponent,
    ],
    imports: [
        CommonModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatCommonModule,
        MatMenuModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatListModule,
        MatOptionModule,
        MatSelectModule,
        MatAutocompleteModule,
        NgxMaterialTimepickerModule,
        MatCheckboxModule,
        MatInputModule,
        RadioButtonModule,
        MatRadioModule
    ]
})
export class PdvFormModule { }
