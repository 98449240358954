<div>
    <div class="logo">
        <img src="../../assets/img/logo.jpeg" width="50px" height="50px" id="icon" alt="User Icon" />
        <h3>Bonjour,</h3>
        <p>Pas encore client,crée ton compte !</p>
        <div class="blue"></div>
    </div>
    <div class="signUpForm">
        <form id="formContent" [formGroup]="registerForm" >
          <input type="text" formControlName="last_name" id="last_name" class="fadeIn second" placeholder="Nom" required>
          <input type="text" formControlName="name" id="name" class="fadeIn second"  placeholder="Prénoms" required>
            <input type="text" formControlName="tel" id="tel" class="fadeIn second"  placeholder="Numéro de téléphone" required>
    
              <!-- <p class="text-danger" *ngIf="form.get('userName')?.hasError('required')">
                Entrez votre identifiant svp
              </p> -->
    
              <input type="text" formControlName="type_identity" id="type_identity" class="fadeIn third"  placeholder="Type de document" required>
              <input type="text" formControlName="num_identity" id="num_identity" class="fadeIn third"  placeholder="N° de la pièce" required>
              <input type="password" formControlName="password" id="password" class="fadeIn third"  placeholder="Mot de passe" required>
              <input type="password" formControlName="confirmPassword" id="confirmPassword" class="fadeIn third"  placeholder="Répétez le mot de passe" required>
            
              <!-- <p class="text-danger" *ngIf="form.get('password')?.hasError('required')">
                Entrez votre mot de passe svp
              </p> -->
              
            <input (click)="register()" type="submit" [disabled]="!registerForm.valid" class="fadeIn fourth" value="Créer le compte" >
            
          </form>
          
        </div>
        <div id="formFooter">
          <div class="underlineHover" routerLink="/connexion" >Vous avez deja un compte? Connectez vous.</div>
        </div>
</div>