import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStorageService } from './helpers/app-storage.service';
import { BaseRepository } from './base/base-repository';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ManagerService extends BaseRepository {
    constructor(http: HttpClient, appStorageService: AppStorageService) {
        super('managers', http, appStorageService);
    }

    getList(id: number) {
        return this.http.get<any>(this.url + "salepoints" + '/' + id + '/' + this.controllerName, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    rejectManager(salepointId:number, id:number){
        return this.http.delete<any>(this.url + "salepoints" + '/' + salepointId + '/' + this.controllerName +'/'+ id, {}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    acceptManager(salepointId:number, id:number){
        return this.http.put<any>(this.url + "salepoints" + '/' + salepointId + '/' + this.controllerName +'/'+ id, {accept:true}).pipe(
            map((res) => {
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }

    
}
