<app-header></app-header>
<div class="row mt-5">
    <div class="col-md-12 mt-5 mb-2 ">
        <div class="red py-2">
            <h4 class="text-center text-bold">
                <i class="bi bi-alarm fs-1"></i> <br><br>
                Votre agence a été créé <br>
                et en attente de validation 
                <br><br>
                Veuillez patienter s’il vous plait
            </h4>
        </div>
    </div>
    <div class="mt-5 d-flex justify-content-center">
        <button class="btn" (click)="backhome()">RETOUR AU MENU PRINCIPAL</button>
    </div>


</div>