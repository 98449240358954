import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { User } from '../Types/user.model'
import { catchError, map, tap, throwError } from 'rxjs'
import { environment } from 'src/environments/environment'
import { AppStorageService } from './helpers/app-storage.service'


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly baseUrl = `${environment.apiUrl}`

    constructor (
    private http: HttpClient,
    private appStorageService: AppStorageService
    ) {}

    getToken (): string | null {
        return localStorage.getItem('AUTH-TOKEN')
    }

    refreshToken (refresh_token: string) {
        const url = `${this.baseUrl}refresh-token`

        return this.http.post<any>(url, { 'refresh_token': refresh_token }).pipe(
            map((response) => {
                console.log('obtention du token a partir du refresh');
                this.appStorageService.setToken(response.data.token)
                this.appStorageService.setRefreshToken(response.data.refresh_token)
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }

                throw(errorMessage)
            })
        )
    }

    login (tel: string, password: string) {
        const url = `${this.baseUrl}login`
        return this.http.post<any>(url, { tel, password }).pipe(
            tap((response) => {
                localStorage.setItem('currentUser', JSON.stringify(response.data))
                this.appStorageService.setToken(response.data.token);
                this.appStorageService.setRefreshToken(response.data.refresh_token);
                return response
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                throw (errorMessage)
            })
        )
    }

    logout () {
        const url = `${this.baseUrl}logout`
        return this.http.post<any>(url, {}).pipe(
            map((res) => {
                console.log('Res', res)
                localStorage.removeItem('currentUser');
                localStorage.removeItem('AUTH-TOKEN');
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                console.log('erreur: ', errorMessage)

                throw(errorMessage)
            })
        )
    }

    addNewUser (user: User) {
        const url = `${this.baseUrl}register`
        return this.http.post<any>(url, user).pipe(
            map((res) => {
                console.log('Res', res)
                return res
            }),
            catchError((err) => {
                let errorMessage = ''
                if (err.error?.message) {
                    errorMessage = err.error.message
                }
                return throwError(errorMessage)
            })
        )
    }
}
