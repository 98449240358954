import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Modules/dashboard/main-menu/main-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AuthentificationComponent } from './Modules/authentification/authentification.component';
import { PortailComponent } from './Modules/portail/portail.component';
import { SignUpComponent } from './Modules/sign-up/sign-up.component';
import { ZoneComponent } from './Modules/dashboard/zone/zone.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
// import { TokenInterceptorProvider } from './api/helpers/token.interceptor';
import {  LOCALE_ID } from '@angular/core';
import { AuthorizationModule } from './Modules/authorization/authorization.module';
import { CommunicationModule } from './Modules/communication/communication.module';
import { SuccessPageComponent } from './Modules/dashboard/pdv-form/success-page/success-page.component';
import { SearchPdvModule } from './Modules/search-pdv/search-pdv.module';
import { LayoutModule } from './Modules/layout/layout.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DialogsModule } from './Modules/dialogs/dialogs.module';
import localeFr from '@angular/common/locales/fr';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthInterceptor } from './api/helpers/auth.interceptor';

registerLocaleData(localeFr);

const config: SocketIoConfig = { url: environment.apiUrl, options: { transports: ['websocket'] }, };

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        AuthentificationComponent,
        PortailComponent,
        SignUpComponent,
        ZoneComponent,
        SuccessPageComponent,
    ],
    imports: [
        SocketIoModule.forRoot(config),
        DialogsModule,
        SearchPdvModule,
        CommunicationModule,
        AuthorizationModule,
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        MatIconModule,
        MatMenuModule,
        LayoutModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    }, { provide: LOCALE_ID, useValue:'fr-FR'}],
    bootstrap: [AppComponent],
})
export class AppModule {}
