<div class="row">
    <div class="col-md-6 mt-4 col-sm-12">
        <div class="title">
            <h5> Horaires</h5>
        </div>
        <form [formGroup]="SheduleForm">

            <div class="opening-hours" formArrayName="Hours">
                <div class="day" *ngFor="let hour of Hours.controls; let i = index" [formGroupName]="i">

                    <input type="text" formControlName="day" readonly>&nbsp;
                    <div class="input-container">

                        <input [ngxTimepicker]="pickerA" formControlName="ouverture" [format]="24" readonly />

                        <ngx-material-timepicker #pickerA></ngx-material-timepicker>

                        <span> à &nbsp; </span>
                        <input [ngxTimepicker]="pickerB" formControlName="fermeture" [format]="24" readonly />

                        <ngx-material-timepicker #pickerB></ngx-material-timepicker>
                    </div>
                    <button (click)="deleteShedule(i)" mat-mini-fab color="warn"
                        aria-label="Example icon button with a delete icon">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>

            <div class="mt-5 d-flex justify-content-center">
                <button class="btn w-50" (click)="prev()"><i class="bi bi-caret-left-fill"></i>Retour</button>
                <button class="btn w-50" (click)="registerShedule()" type="submit">Valider</button>

            </div>
        </form>
    </div>
    <div class="col-md-6 col-sm-12">
        <div class="fond" style="height: 50vh; overflowY: scroll; overflowX: hidden">
            <table class="table tab" *ngIf="hoursTab.length!==0">
                <tr>
                    <th>Jour</th>
                    <th>Ouverture</th>
                    <th>Fermeture</th>
                </tr>
                <tr *ngFor="let hour of trierParJoursSemaine(hoursTab); ">
                    <td>{{hour.day}} </td>
                    <td>{{removeSeconds(hour.ouverture)}} </td>
                    <td>{{removeSeconds(hour.fermeture)}}</td>
                </tr>
            </table>
        </div>
        <div class="mt-5 d-flex justify-content-center">
            <button class="btn w-50" (click)="next()">Suivant <i class="bi bi-caret-right-fill"></i></button>
        </div>
    </div>
</div>