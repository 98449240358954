
<!-- <app-header></app-header> -->
<div class="container grdBlocck">
    <div class="container text-white mb-5">
        <div class="row">
            <div class="zone w-100">
                <h1 class="text-center">Zone</h1>
            </div>
        </div>
    </div>

    <div class="container text-white">
        <div class="row justify-content-evenly">

            <div class="block m-3 d-flex justify-content-start">
                
                <h3>Zone</h3>
            </div>


            <div class="block m-3 d-flex justify-content-start">
                
                <h3>Nature agence uveon</h3>
            </div>

        </div>
    </div>
    <div class="container text-white">
        <div class="row justify-content-evenly">

            <div class="block m-3 d-flex justify-content-start">
                
                <h3>Statut agence</h3>
            </div>


            <div class="block m-3 d-flex justify-content-start">
                
                <h3>Produit recherché</h3>
            </div>

        </div>
    </div>
    
    <div class="container text-white">
        <div class="row justify-content-evenly">

            <div class="block m-3 d-flex justify-content-start">
               
                <h3>Accès Service Uveon</h3>
            </div>


            <div class="block m-3 d-flex justify-content-start">
                
                <h3>Produit manquant</h3>
            </div>

        </div>
    </div>
    <div class="container text-white">
        <div class="row justify-content-evenly">

            <div class="block m-3 d-flex justify-content-start">
               
                <h3>Structure de l’agence</h3>
            </div>


            <div class="block m-3 d-flex justify-content-start">
               
                <h3>Nom de l’agence</h3>
            </div>

        </div>
    </div>
    <div class="container text-white">
        <div class="row justify-content-center">
            <div class="search m-3 d-flex">
                <div class="col">
                    <i class="bi bi-search"></i>
                </div>
                <div class="col">
                    <h3>RECHERCHER</h3>
                </div>
                <div class="col">
                </div>
            </div>


        </div>
    </div>
</div>